import React, { Component } from 'react';
import { css } from '@emotion/react';
import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';
import Vstack3CellExtremity from '../atoms/Vstack3CellExtremity';
import ContentSpacer from '../atoms/ContentSpacer';
import Header from '../atoms/Header';
import BodyText from '../atoms/BodyText';
import ScrollsAreFree from '../atoms/ScrollsAreFree';
import * as Breakpoints from '../../../constants/breakpoints';
import HstackSpacing, { HstackSpacingEnum } from '../atoms/HstackSpacing';
import * as InternalPropTypes from '../../../constants/internal-types';
import { fontColorEnum } from '../../../constants/cms-constants';

const styles = css({
  // ratio locking
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '53%',
  },
  [Breakpoints.Tablet.mq]: {
    paddingTop: '70%',
  },
  [Breakpoints.Mobile.mq]: {
    minHeight: '415px',
  },
  position: 'relative',
  '& .inner-container': {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
  },
  '& .content-container': {
    height: '100%',
    '& .item-center': {
      [Breakpoints.DesktopUp.mq]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& .content-spacer': {
      width: '100%',
      height: '100%',
    },
    '& .header-text, & .header-body-text, & .footer-body-text': {
      color: fontColorEnum.WHITE,
    },
    '& .header-body-text': {
      [Breakpoints.DesktopUp.mq]: {
        lineHeight: '21px',
      },
      [Breakpoints.TabletDown.mq]: {
        lineHeight: '18px',
      },
    },
    '& .header-grid': {
      [Breakpoints.DesktopUp.mq]: {
        paddingTop: '100px',
      },
      [Breakpoints.Tablet.mq]: {
        paddingTop: '140px',
        paddingRight: '30%',
      },
      [Breakpoints.TabletDown.mq]: {
        '& .right-cell': {
          marginTop: '20px',
        },
      },
      [Breakpoints.Mobile.mq]: {
        paddingTop: '100px',
        paddingRight: '15%',
      },
      '& .header-text': {
        marginRight: '20%',
      },
      '& .right-cell': {
        alignSelf: 'flex-end',
      },
    },
    '& .details-grid': {
      '& .scrolls-animation': {
        marginTop: '5px',
        marginLeft: '3px',
      },
      '& .right-cell': {
        [Breakpoints.Mobile.mq]: {
          display: 'none',
        },
      },
      '& .middle-snippet': {
        marginRight: '1em',
      },
    },
  },
});

type FrontPageHeroProps = {
  headerText?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  leftFooterText?: InternalPropTypes.RichText;
  middleFooterText?: InternalPropTypes.RichText;
  rightFooterText?: InternalPropTypes.RichText;
  spacing?: HstackSpacingEnum;
};

class FrontPageHero extends Component<FrontPageHeroProps> {
  static defaultProps = {
    headerText: null,
    bodyText: null,
    leftFooterText: null,
    middleFooterText: null,
    rightFooterText: null,
    spacing: HstackSpacing.SPACEBETWEEN,
  };

  render() {
    const {
      headerText,
      bodyText,
      leftFooterText,
      middleFooterText,
      rightFooterText,
      spacing,
    } = this.props;

    return (
      <div css={styles}>
        <div className="inner-container">
          <div className="content-container">
            <ContentSpacer>
              <Vstack3CellExtremity
                className="vstack"
                onRenderMiddleCell={() => (
                  <Grid2CellUnevenSplit
                    leftCellCount={7}
                    rightCellCount={5}
                    className="header-grid"
                    onRenderCell1={() => (
                      <Header className="header-text" type={Header.types.HERO}>
                        {headerText}
                      </Header>
                    )}
                    onRenderCell2={() => (
                      <BodyText
                        className="header-body-text"
                        type={BodyText.types.LANDINGHERO}
                      >
                        {bodyText}
                      </BodyText>
                    )}
                    snapAtBreakpoint={Breakpoints.Tablet}
                  />
                )}
                onRenderBottomCell={() => (
                  <Grid2CellUnevenSplit
                    leftCellCount={7}
                    rightCellCount={5}
                    className="details-grid"
                    snapAtBreakpoint={Breakpoints.MobileDown}
                    onRenderCell1={() => (
                      <>
                        <BodyText
                          className="footer-body-text"
                          type={BodyText.types.LIGHT12}
                        >
                          {leftFooterText}
                        </BodyText>
                        <ScrollsAreFree className="scrolls-animation" />
                      </>
                    )}
                    onRenderCell2={() => (
                      <HstackSpacing spacing={spacing}>
                        <BodyText
                          className="footer-body-text middle-snippet"
                          type={BodyText.types.LIGHT12}
                        >
                          {middleFooterText}
                        </BodyText>
                        <BodyText
                          className="footer-body-text"
                          type={BodyText.types.LIGHT12}
                        >
                          {rightFooterText}
                        </BodyText>
                      </HstackSpacing>
                    )}
                  />
                )}
              />
            </ContentSpacer>
          </div>
        </div>
      </div>
    );
  }
}

export default FrontPageHero;
