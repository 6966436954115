import { BlockInstanceProps } from '../Block';
import Quote from '../../components/Quote';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import {
  QuoteTypeEnum,
  BackgroundColorEnum,
} from '../../constants/cms-constants';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'type',
    definition: controlOptionDefinitions.quoteTypes,
  },
])
class QuoteBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor =
      BackgroundColorEnum[controlOptions?.backgroundColor];
    const type = QuoteTypeEnum[controlOptions?.type];

    return (
      <Quote
        quote={block?.header?.main}
        sender={block?.header?.light}
        type={type}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default QuoteBlock;
