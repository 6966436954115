const BlockTypes = {
  ARTICLE: 'Article',
  ARTICLEGRID: 'Article grid',
  BANNER: 'Banner',
  BODYTEXT: 'Body text',
  BUZZLIST: 'Buzz list',
  CALLTOACTIONWITHHEADER: 'Call to action with header',
  CASEGRID: 'Case grid',
  CASEHEADER: 'Case header',
  CASEHERO: 'Case hero',
  CASESHERO: 'Cases hero',
  COOKIEACCORDION: 'Cookie accordion',
  COOKIESETTINGS: 'Cookie settings',
  CUECARDGRID: 'Cue card grid',
  EVENTPASTGRID: 'Event past grid',
  EVENTPROMO: 'Event promo',
  EVENTUPCOMING: 'Event up coming',
  FILEREQUESTFORM: 'File request form',
  FACTBOX: 'Fact box',
  FINDUS: 'Find us',
  FLOATINGIMAGE: 'Floating image',
  FRONTPAGEHERO: 'Front page hero',
  HEADER2TEXTGROUP: 'Header 2 text group',
  HEADERTEXTGROUP: 'Header text group',
  HERO: 'Hero',
  HORIZONTALLINE: 'Horizontal line',
  IMAGECAROUSEL: 'Image carousel',
  IMAGEGRID2CELLS: 'Image grid 2 cells',
  IMAGEGRID3CELLS: 'Image grid 3 cells',
  IMAGEGRID3EVENCELLS: 'Image grid 3 even cells',
  IMAGELEFTANDTEXTRIGHT: 'Image left and text right',
  IMAGETEXTS: 'Image texts',
  IMAGETWOTEXTS: 'Image two texts',
  KEYFIGUREGRID: 'Key Figure Grid',
  LETSTALK: 'Lets talk',
  LIST3GROUPS: 'List 3 groups',
  LOGOSCROLLER: 'Logo scroller',
  NAVIGABLELIST: 'Navigable list',
  NEXTPAGE: 'Next page',
  OEMBEDDING: 'OEmbedding',
  PROFILE: 'Profile',
  PROFILEAUTHOR: 'Profile author',
  PROFILEGRID: 'Profile grid',
  QUOTE: 'Quote',
  QUOTECAROUSEL: 'QuoteCarousel',
  RELATEDPAGESGRID: 'Related pages',
  SECTIONTOP: 'Section top',
  SIDEBOX: 'Side box',
  SLIDESHOW: 'Slide show',
  SUBPAGEGRID: 'Subpage grid',
  VALUECAROUSEL: 'Value carousel',
  WELLFULLSIZE: 'Well full size',
  WELLGRID: 'Well grid',
};

module.exports = BlockTypes;
