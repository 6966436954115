import { BlockInstanceProps } from '../Block';
import {
  ContentTypeEnum,
  BackgroundColorEnum,
} from '../../constants/cms-constants';
import ImageCarousel from '../../components/ImageCarousel';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class ImageCarouselBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor =
      BackgroundColorEnum[controlOptions?.backgroundColor];
    let images;
    blockContent.doQuery((content) => {
      images = content.getAllOfType(ContentTypeEnum.IMAGE);
    });

    return (
      <ImageCarousel
        header={block?.header?.main}
        lightHeader={block?.header?.light}
        images={images}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default ImageCarouselBlock;
