import { BlockInstanceProps } from '../Block';
import FindUsComponent from '../../components/FindUsComponent';
import {
  ContentTypeEnum,
  BackgroundColorEnum,
} from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class FindUsBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor =
      BackgroundColorEnum[controlOptions?.backgroundColor];

    let images;
    blockContent.doQuery((content) => {
      images = content.getFirstOfType(ContentTypeEnum.IMAGE);
    });

    return (
      <FindUsComponent
        locationText={block?.header?.main}
        addressText={block?.bodyText}
        image={images}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default FindUsBlock;
