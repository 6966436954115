import { Component } from 'react';

import * as InternalPropTypes from '../constants/internal-types';
import EventPromoMolecules from './common/molecules/EventPromo';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import {
  TypeBackgroundColorTypes,
  TypeSEOWeightTypes,
} from '../constants/cms-constants';
import SEOWeights from '../constants/cms/control-options/seo-weights';

type EventPromoProps = {
  promoEvent?: InternalPropTypes.Event;
  backgroundColor?: TypeBackgroundColorTypes;
  seoWeight?: TypeSEOWeightTypes;
};

class EventPromo extends Component<EventPromoProps> {
  static defaultProps = {
    promoEvent: null,
    backgroundColor: null,
    seoWeight: SEOWeights.H2,
  };

  render() {
    const { promoEvent, backgroundColor, seoWeight } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div css={addStyle} className="event-promo-component">
        <ContentOuterBound>
          <ContentSpacer>
            {promoEvent && (
              <EventPromoMolecules
                title={promoEvent.title}
                image={promoEvent.image}
                date={promoEvent.date}
                geoLocation={promoEvent.geoLocation}
                onlineLocation={promoEvent.onlineLocation}
                teaser={promoEvent.teaser}
                language={promoEvent.language}
                seoWeight={seoWeight}
              />
            )}
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default EventPromo;
