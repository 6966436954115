import { Component } from 'react';
import { css } from '@emotion/react';
import * as InternalPropTypes from '../../../constants/internal-types';
import Image from '../atoms/Image';
import { SizesType } from '../../../types/ts/imgixQuery';
import ScreenSize from '../../../constants/screenSize';
import * as Breakpoints from '../../../constants/breakpoints';
import {
  FloatingImageEnum,
  TypeFloatingImageTypes,
} from '../../../constants/cms-constants';

const styles = css({
  maxHeight: '700px',
  maxWidth: '50%',
  '&.left': {
    [Breakpoints.Mobile.mq]: {
      maxWidth: '100%',
      maxHeight: '500px',
      width: '100%',
      margin: '10px 10px 10px -10px',
    },

    [Breakpoints.Tablet.mq]: {
      margin: '10px 10px 10px -60px',
      float: 'left',
    },
    [Breakpoints.DesktopUp.mq]: {
      margin: '10px 10px 10px -100px',
      float: 'left',
    },
  },
  '&.right': {
    [Breakpoints.Mobile.mq]: {
      maxWidth: '100%',
      maxHeight: '500px',
      width: '100%',
      margin: '10px 10px 10px -10px',
    },

    [Breakpoints.Tablet.mq]: {
      margin: '10px 0px 10px 10px',
      float: 'right',
    },
    [Breakpoints.DesktopUp.mq]: {
      margin: '10px 0px 10px 10px',
      float: 'right',
    },
  },
});

type FloatingImageProps = {
  type?: TypeFloatingImageTypes;
  image?: InternalPropTypes.Image;
};

class FloatingImage extends Component<FloatingImageProps> {
  static types = FloatingImageEnum;
  static defaultProps = {
    type: this.types.LEFT,
    image: null,
  };

  render() {
    const { type, image } = this.props;
    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '743', 'max-h': '500' },
      [ScreenSize.Tablet]: { w: '903', 'max-h': '700' },
      [ScreenSize.Desktop]: { w: '1000', 'max-h': '700' },
    };

    return (
      <div css={[styles]} className={`${type}`}>
        <Image image={image} sizes={sizes} />
      </div>
    );
  }
}

export default FloatingImage;
