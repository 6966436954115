import { BlockInstanceProps } from '../Block';
import NavigableList from '../../components/NavigableList';
import {
  ContentTypeEnum,
  BackgroundColorEnum,
} from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class NavigableListBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor =
      BackgroundColorEnum[controlOptions?.backgroundColor];

    let mediaItems;
    blockContent.doQuery((content) => {
      mediaItems = content.getAllOfType(ContentTypeEnum.MEDIAITEM);
    });

    return (
      <NavigableList content={mediaItems} backgroundColor={backgroundColor} />
    );
  }
}

export default NavigableListBlock;
