import { Component } from 'react';
import { css } from '@emotion/react';

import GridEvenSplit from '../atoms/GridEvenSplit';
import MediaQuery from '../atoms/MediaQuery';
import {
  BackgroundColorEnum,
  TypeWellTypes,
} from '../../../constants/cms-constants';
import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import Well from './Well';
import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';
import getRows from '../../../utils/utilFunctions';

const styles = css({
  '& .left-cell': {
    '& .client, .partner': {
      [Breakpoints.Mobile.mq]: {
        boxShadow: `-8px 0 0 0 ${BackgroundColorEnum.WHITE} inset`,
        '& .image': {
          position: 'relative',
          left: '-4px',
        },
      },
    },
    '& .client': {
      [Breakpoints.TabletUp.mq]: {
        boxShadow: `-16px 0 0 0 ${BackgroundColorEnum.WHITE} inset`,
        '& .image': {
          position: 'relative',
          left: '-8px',
        },
      },
    },
    '& .partner': {
      [Breakpoints.Tablet.mq]: {
        boxShadow: `-16px 0 0 0 ${BackgroundColorEnum.WHITE} inset`,
        '& .image': {
          position: 'relative',
          left: '-8px',
        },
      },
      [Breakpoints.DesktopUp.mq]: {
        boxShadow: `-12px 0 0 0 ${BackgroundColorEnum.WHITE} inset`,
        '& .image': {
          position: 'relative',
          left: '-6px',
        },
      },
    },
  },
  '& .center-cell': {
    '& .client, .partner': {
      [Breakpoints.DesktopUp.mq]: {
        boxShadow: `-8px 0 0 0 ${BackgroundColorEnum.WHITE} inset, 8px 0 0 0 ${BackgroundColorEnum.WHITE} inset`,
      },
    },
  },
  '& .right-cell': {
    '& .client, .partner': {
      [Breakpoints.Mobile.mq]: {
        boxShadow: `8px 0 0 0 ${BackgroundColorEnum.WHITE} inset`,
        '& .image': {
          position: 'relative',
          left: '4px',
        },
      },
    },
    '& .client': {
      [Breakpoints.TabletUp.mq]: {
        boxShadow: `16px 0 0 0 ${BackgroundColorEnum.WHITE} inset`,
        '& .image': {
          position: 'relative',
          left: '8px',
        },
      },
    },
    '& .partner': {
      [Breakpoints.Tablet.mq]: {
        boxShadow: `16px 0 0 0 ${BackgroundColorEnum.WHITE} inset`,
        '& .image': {
          position: 'relative',
          left: '8px',
        },
      },
      [Breakpoints.DesktopUp.mq]: {
        boxShadow: `12px 0 0 0 ${BackgroundColorEnum.WHITE} inset`,
        '& .image': {
          position: 'relative',
          left: '6px',
        },
      },
    },
  },
});

const cellStyle = css({
  boxShadow: `-12px 0 0 0 ${BackgroundColorEnum.WHITE} inset`,
});

type WellGridProps = {
  items?: InternalPropTypes.MediaItem[];
  type?: TypeWellTypes;
};
class WellGrid extends Component<WellGridProps> {
  static defaultProps = {
    items: [],
    type: null,
  };

  static types = Well.types;

  render() {
    const { items, type } = this.props;
    const makeWell = (mediaItem: InternalPropTypes.MediaItem) => (
      <Well image={mediaItem.image} type={type} link={mediaItem.link} />
    );
    const rowsOf2: InternalPropTypes.MediaItem[][] = getRows(items, 2);
    const rowsOf3: InternalPropTypes.MediaItem[][] = getRows(items, 3);
    return (
      <div css={styles}>
        <MediaQuery visible={Breakpoints.Mobile}>
          {rowsOf2.map((row, index) => (
            <GridEvenSplit
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              snapAtBreakpoint={null}
              onRenderCells={[
                () => makeWell(row[0]),
                () => (row[1] ? makeWell(row[1]) : null),
              ]}
            />
          ))}
        </MediaQuery>
        <MediaQuery visible={Breakpoints.Tablet}>
          {rowsOf2.map((row, index) => (
            <GridEvenSplit
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onRenderCells={[
                () => makeWell(row[0]),
                () => (row[1] ? makeWell(row[1]) : null),
              ]}
            />
          ))}
        </MediaQuery>
        <MediaQuery visible={[Breakpoints.Desktop, Breakpoints.DesktopBounded]}>
          {type === 'client' ? (
            rowsOf3.map((row, index) => (
              <GridEvenSplit
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onRenderCells={[
                  () => makeWell(row[0]),
                  () => (row[1] ? makeWell(row[1]) : null),
                  () => (row[2] ? makeWell(row[2]) : null),
                ]}
              />
            ))
          ) : (
            <Grid2CellUnevenSplit
              leftCellCount={4}
              rightCellCount={7}
              onRenderCell2={() =>
                rowsOf2.map((row, index) => (
                  <GridEvenSplit
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    onRenderCells={[
                      () => (
                        <Well
                          image={row[0].image}
                          type={type}
                          style={cellStyle}
                        />
                      ),
                      () =>
                        row[1] ? (
                          <Well
                            image={row[1].image}
                            type={type}
                            style={cellStyle}
                          />
                        ) : null,
                    ]}
                  />
                ))
              }
            />
          )}
        </MediaQuery>
      </div>
    );
  }
}

export default WellGrid;
