import { Component } from 'react';
import { keyframes, css } from '@emotion/react';
import { BackgroundColorEnum } from '../../../constants/cms-constants';

const scrollAnimationBottom = keyframes`
    35% {
      clip-path: inset(0px 0px 0px 0px);
     }
    55% {
      clip-path: inset(0px 0px 10px 0px);
     }
    70% {
      clip-path: inset(0px 0px 0px 0px);
     }
    90% {
      clip-path: inset(50px 0px 0px 0px);
     }   
    100% {
     clip-path: inset(50px 0px 0px 0px);
    } 
  `;

const scrollAnimationTop = keyframes`
    69% {
      clip-path: inset(0px 0px 50px 1px);
     }
    70% {
      clip-path: inset(0px 0px 50px 0px);
     }
    100% {
      clip-path: inset(0px 0px 0px 0px);
     }   
  `;

const style = css({
  position: 'relative',
  height: '52px',
  '& .top-line': {
    position: 'absolute',
    top: '0',
    width: '1px',
    height: 'inherit',
    clipPath: 'inset(0px 0px 50px 1px)',
    background: BackgroundColorEnum.WHITE,
    animation: `${scrollAnimationTop} 3s infinite`,
  },
  '& .bottom-line': {
    position: 'absolute',
    bottom: '0',
    width: '1px',
    height: 'inherit',
    clipPath: 'inset(0px 0px 0px 0px)',
    background: BackgroundColorEnum.WHITE,
    animation: `${scrollAnimationBottom} 3s infinite`,
  },
});

type ScrollsAreFreeProps = {
  className?: string;
};

class ScrollsAreFree extends Component<ScrollsAreFreeProps> {
  static defaultProps = {
    className: null,
  };

  render() {
    const { className } = this.props;

    return (
      <div
        css={style}
        className={`scrolls-are-free-atom${className ? ` ${className}` : ''}`}
      >
        <div className="top-line" />
        <div className="bottom-line" />
      </div>
    );
  }
}

export default ScrollsAreFree;
