import { Component } from 'react';

import BodyText from '../atoms/BodyText';
import Title from '../atoms/Title';
import * as InternalPropTypes from '../../../constants/internal-types';
import TitleTypes from '../../../constants/title-types';
import { TypeSEOWeightTypes } from '../../../constants/cms-constants';
import getSEOWeight from '../../../utils/SEOWeight';
import SEOWeights from '../../../constants/cms/control-options/seo-weights';

const styles = {
  '& .title': {
    marginBottom: '16px',
  },
};
type TitleTextGroupProps = {
  titleText?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  type?: TitleTypes;
  seoWeight?: TypeSEOWeightTypes;
};

class TitleTextGroup extends Component<TitleTextGroupProps> {
  static types = TitleTypes;

  static defaultProps = {
    titleText: null,
    bodyText: null,
    type: TitleTypes.SECTION,
    seoWeight: SEOWeights.H3,
  };

  render() {
    const { titleText, bodyText, type, seoWeight } = this.props;
    const addSEOWeight = getSEOWeight(
      seoWeight,
      TitleTextGroup.defaultProps.seoWeight,
    );

    return (
      <div className="title-text-group" css={styles}>
        <Title seoWeight={addSEOWeight} className="title" type={type}>
          {titleText}
        </Title>

        <BodyText type={BodyText.types.STATIC16}>{bodyText}</BodyText>
      </div>
    );
  }
}

export default TitleTextGroup;
