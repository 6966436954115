import { Component } from 'react';
import { css, keyframes } from '@emotion/react';

import angledArrow from '../../../assets/angledArrow.png';

import { defaultColors } from '../../../constants/colors';
import { BackgroundColorEnum } from '../../../constants/cms-constants';

const animations = {
  onHover: keyframes`
    0% {
      background-position: 0% 0;
      } 
    100% {
      background-position: -100% 0;
      }
  `,
  offHover: keyframes`
    0% {
      background-position: -100% 0;
      } 
    100% {
      background-position: -200% 0;
      }
  `,
};

const styles = css({
  width: '42px',
  height: '42px',
  borderRadius: '50%',
  border: `1px solid ${defaultColors.lineBreak}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(to right, ${BackgroundColorEnum.WHITE} 50%, ${defaultColors.linkHover} 50%)`,
  backgroundSize: '200% auto',
  animation: `${animations.offHover} 700ms`,
  ':hover': {
    animation: `${animations.onHover} 700ms`,
    backgroundPosition: '-100% 0',
  },
  '&:focus': {
    outline: 'none',
  },
  '& .arrow': {
    width: '15px',
    height: '15px',
    objectFit: 'scale-down',
  },
  '& .left': {
    transform: 'scaleX(-1)',
  },
});

type ArrowButtonProps = {
  direction?: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onClick?: (...args: any[]) => any;
};

class ArrowButton extends Component<ArrowButtonProps> {
  static defaultProps = {
    direction: '',
    onClick: () => {},
  };

  render() {
    const { direction, ...other } = this.props;
    return (
      <button type="button" className="ArrowButton" css={styles} {...other}>
        <img className={`arrow ${direction}`} src={angledArrow} alt="arrow" />
      </button>
    );
  }
}

export default ArrowButton;
