import { BlockInstanceProps } from '../Block';
import CasesPageHero from '../../components/CasesPageHero';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import { BackgroundColorEnum } from '../../constants/cms-constants';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class CasesHeroBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor =
      BackgroundColorEnum[controlOptions?.backgroundColor];
    return (
      <CasesPageHero
        header={block?.header?.main}
        lightHeader={block?.header?.light}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default CasesHeroBlock;
