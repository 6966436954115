import { BlockInstanceProps } from '../Block';
import HeaderTextGroup from '../../components/HeaderTextGroup';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import HeaderTextGroupTypes from '../../constants/cms/control-options/header-text-group-types';
import { BackgroundColorEnum } from '../../constants/cms-constants';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'type',
    definition: controlOptionDefinitions.headerTextGroupTypes,
  },
  {
    key: 'seoWeight',
    definition: controlOptionDefinitions.SEOWeightTypes,
  },
])
class HeaderTextGroupBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  static types = HeaderTextGroup.types;

  render() {
    const { block } = this.props;

    const { seoWeight } = this.controlOptions;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor =
      BackgroundColorEnum[controlOptions?.backgroundColor];
    const type = HeaderTextGroupTypes[controlOptions?.type];

    return (
      <HeaderTextGroup
        headerText={block?.header?.main}
        bodyText={block?.bodyText}
        link={block?.link}
        type={type}
        backgroundColor={backgroundColor}
        seoWeight={seoWeight}
      />
    );
  }
}

export default HeaderTextGroupBlock;
