import { Component } from 'react';
import { css } from '@emotion/react';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';

import SubpageTeaser from './SubpageTeaser';

import { TypeSEOWeightTypes } from '../../../constants/cms-constants';
import SEOWeights from '../../../constants/cms/control-options/seo-weights';
import getSEOWeight, { getLowerSEOWeight } from '../../../utils/SEOWeight';
import Header, { HeaderEnum } from '../atoms/Header';

const styles = css({
  '& .subpage-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    paddingLeft: '8px',
    paddingRight: '8px',
    [Breakpoints.Mobile.mq]: {
      maxWidth: '100%',
    },
    [Breakpoints.Tablet.mq]: {
      maxWidth: '50%',
    },
    [Breakpoints.DesktopUp.mq]: {
      maxWidth: '33.333333%',
    },
    '& .image-container': {
      overflow: 'hidden',
      alignContent: 'end',
      [Breakpoints.Mobile.mq]: {
        maxHeight: '336px',
      },
      [Breakpoints.TabletUp.mq]: {
        maxHeight: '176px',
      },
    },
    '& .image': {
      transform: 'scale(1.0)',
      transition: 'all 200ms linear',
    },
    '& :hover': {
      '& .article-title': {
        textDecoration: 'underline',
      },
      '& .image': {
        transform: 'scale(1.05)',
        transition: 'all 200ms linear',
      },
    },
  },
  '& .grid-header': {
    paddingBottom: '30px',
    paddingTop: '30px',
  },
});

type SubpageGridProps = {
  header?: InternalPropTypes.RichText;
  items?: InternalPropTypes.MediaItem[];
  seoWeight?: TypeSEOWeightTypes;
};

class SubpageGrid extends Component<SubpageGridProps> {
  static defaultProps = {
    header: null,
    items: [],
    seoWeight: SEOWeights.H2,
  };

  render() {
    const { header, items, seoWeight } = this.props;
    const addSEOWeight = getSEOWeight(seoWeight, seoWeight);
    const lowerSEOWeight = getLowerSEOWeight(seoWeight);
    return (
      <div css={styles}>
        <div>
          {header && (
            <Header
              seoWeight={addSEOWeight}
              className="grid-header"
              type={HeaderEnum.NAMEBASE}
            >
              {header}
            </Header>
          )}
        </div>
        <div className="container-fluid">
          <div className="row image-padding">
            {items.map((item, index) => (
              <div
                className="subpage-container col-12 col-md-6 col-lg-4"
                // eslint-disable-next-line react/no-array-index-key
                key={`subpage-teaser-${index}`}
              >
                <SubpageTeaser
                  image={item.image}
                  title={item.header}
                  text={item.bodyText}
                  link={item.link}
                  seoWeight={lowerSEOWeight}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default SubpageGrid;
