import { Component } from 'react';
import { css } from '@emotion/react';

import NwLink from '../NwLink';
import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';
import Image from '../atoms/Image';
import Title from '../atoms/Title';
import TitleTypes from '../../../constants/title-types';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import BodyText from '../atoms/BodyText';
import { getDateStringDayMonthYear } from '../../../utils/content/Events';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';
import font from '../../../constants/fonts';
import SEOWeights from '../../../constants/cms/control-options/seo-weights';
import {
  TypeSEOWeightTypes,
  fontColorEnum,
} from '../../../constants/cms-constants';
import getSEOWeight from '../../../utils/SEOWeight';

const styles = css({
  '& .promo-image': {
    overflow: 'hidden',
    alignItems: 'flex',
    [Breakpoints.Mobile.mq]: {
      margin: '15px 5px 5px 5px',
    },
    [Breakpoints.TabletUp.mq]: {
      margin: '20px 20px 20px 0px',
    },
  },
  '& :hover': {
    textDecoration: 'none',
    '& .header-text': {
      textDecoration: 'underline',
    },
    '& .image': {
      transform: 'scale(1.05)',
    },
  },
  '& .image': {
    display: 'block',
    width: '100%',
    objectFit: 'scale-down',
    maxHeight: '1000px',
    [Breakpoints.Mobile.mq]: {
      maxHeight: '40px',
      visibility: 'hidden',
    },
  },
  '& .header-text': {
    ...font.GNBold,
    color: fontColorEnum.WHITE,
    paddingTop: '50px',
    fontWeight: '450',
    [Breakpoints.Mobile.mq]: {
      paddingTop: '15px',
      fontSize: '28px',
      lineHeight: '30px',
    },
    [Breakpoints.TabletUp.mq]: {
      paddingTop: '20px',
      fontSize: '36px',
      lineHeight: '34px',
    },
  },
  '& .header-2-text-group': {
    color: fontColorEnum.WHITE,
    marginTop: '30px',
    fontWeight: '300',
  },
  '& .article': {
    color: fontColorEnum.WHITE,
    fontWeight: '300',
    marginTop: '30px',
    marginBottom: '30px',
    fontSize: '16px',
    [Breakpoints.TabletUp.mq]: {
      fontSize: '20px',
    },
  },
});

type EventPromoProps = {
  title?: string;
  date?: number;
  teaser?: InternalPropTypes.RichText;
  geoLocation?: string;
  image?: InternalPropTypes.Image;
  onlineLocation?: InternalPropTypes.Link;
  language?: InternalPropTypes.LanguageName;
  seoWeight?: TypeSEOWeightTypes;
};

class EventPromo extends Component<EventPromoProps> {
  static defaultProps = {
    title: '',
    date: null,
    teaser: '',
    geoLocation: '',
    image: null,
    onlineLocation: { linkTo: '' },
    language: null,
    seoWeight: SEOWeights.H2,
  };

  render() {
    const {
      title,
      date,
      teaser,
      geoLocation,
      image,
      onlineLocation,
      language,
      seoWeight,
    } = this.props;

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '733', h: '240' },
      [ScreenSize.Tablet]: { w: '261', h: '300' },
      [ScreenSize.Desktop]: { w: '733', h: '1000' },
    };

    const addSEOWeight = getSEOWeight(seoWeight, seoWeight);

    return (
      <div css={styles}>
        {title && (
          <NwLink
            to={onlineLocation.linkTo}
            aria-label={title}
            openInNewWindowOrTab={onlineLocation.openInNewWindowOrTab}
          >
            <Grid2CellUnevenSplit
              leftCellCount={3}
              rightCellCount={9}
              onRenderCell1={() => (
                <div className="outer-left-cell">
                  <div className="promo-image">
                    {title && (
                      <Image className="image" image={image} sizes={sizes} />
                    )}
                  </div>
                </div>
              )}
              onRenderCell2={() => (
                <div className="outer-right-cell">
                  <Title
                    type={TitleTypes.SECTION}
                    seoWeight={addSEOWeight}
                    className="header-text"
                  >
                    {title}
                  </Title>
                  <BodyText type={BodyText.types.HEADER2TEXTGROUP}>
                    {getDateStringDayMonthYear(date, language)} {geoLocation}
                  </BodyText>
                  <BodyText type={BodyText.types.ARTICLE}>{teaser}</BodyText>
                </div>
              )}
            />
          </NwLink>
        )}
      </div>
    );
  }
}

export default EventPromo;
