import { BlockInstanceProps } from '../Block';
import CueCardGrid from '../../components/CueCardGrid';
import { ContentTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  { key: 'withLinks', definition: controlOptionDefinitions.boolean },
])
class CueCardGridBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const { backgroundColor } = this.controlOptions;
    const controlOptions = block?.controlOptionsV2;
    const withLinks = controlOptions?.withLinks;

    let mediaItems;
    blockContent.doQuery((content) => {
      mediaItems = content.getAllOfType(ContentTypeEnum.MEDIAITEM);
    });

    return (
      <CueCardGrid
        mediaItems={mediaItems}
        backgroundColor={backgroundColor}
        withLinks={withLinks}
      />
    );
  }
}

export default CueCardGridBlock;
