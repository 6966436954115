import { Component } from 'react';
import { BlockInstanceProps } from '../Block';
import FactBox from '../../components/FactBox';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import TitleTypes from '../../constants/title-types';
import { BackgroundColorEnum } from '../../constants/cms-constants';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'type',
    definition: controlOptionDefinitions.FactboxTypes,
  },
])
class FactBoxBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor =
      BackgroundColorEnum[controlOptions?.backgroundColor];
    const type = TitleTypes[controlOptions?.type];

    return (
      <FactBox
        titleText={block?.header?.main}
        bodyText={block?.bodyText}
        type={type}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default FactBoxBlock;
