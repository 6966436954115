import { IPageViewInput } from '../../constants/reporterServiceInputs';

export default class PageViewInput implements IPageViewInput {
  referrer: string;
  prevPage: string;
  currentPage: string;
  exitPage: string;
  startPage: string;

  constructor(
    referrer: string,
    prevPage: string,
    currentPage: string,
    exitPage: string,
    startPage: string,
  ) {
    this.referrer = referrer;
    this.prevPage = prevPage;
    this.currentPage = currentPage;
    this.exitPage = exitPage;
    this.startPage = startPage;
  }
}
