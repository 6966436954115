import { Component } from 'react';
import { css } from '@emotion/react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as InternalPropTypes from '../constants/internal-types';
import {
  TypeBackgroundColorTypes,
  fontColorEnum,
} from '../constants/cms-constants';
import Accordion from './common/molecules/Accordion';
import Table from './common/molecules/Table';
import BodyText from './common/atoms/BodyText';

const Styles = css({
  '&.cookie-accordion-component': {
    paddingTop: '40px',
    paddingBottom: '40px',
  },
  '.body-text': {
    color: fontColorEnum.BLACK,
    paddingBottom: '20px',
  },
});

type CookieAccordionComponentProps = {
  header?: InternalPropTypes.RichText;
  body?: InternalPropTypes.RichText;
  backgroundColor?: TypeBackgroundColorTypes;
  tableContents?: object[];
};

class CookieAccordionComponent extends Component<CookieAccordionComponentProps> {
  static defaultProps = {
    header: 'Header',
    body: 'Body text',
    backgroundColor: null,
    tableContents: null,
  };

  render() {
    const { header, body, tableContents, backgroundColor } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className="cookie-accordion-component" css={[Styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <Accordion header={header}>
              <BodyText className="body-text" type={BodyText.types.STATIC16}>
                {body}
              </BodyText>
              {tableContents.length > 0 && <Table values={tableContents} />}
            </Accordion>
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default CookieAccordionComponent;
