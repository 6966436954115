import FloatingImage from '../../components/FloatingImage';
import {
  BackgroundColorEnum,
  ContentTypeEnum,
  FloatingImageEnum,
} from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import { BlockInstanceProps } from '../Block';

@ControlOption([
  {
    key: 'type',
    definition: controlOptionDefinitions.floatingImagetypes,
  },
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class FloatingImageBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  static types = FloatingImage.types;
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block.controlOptionsV2;
    const backgroundColor =
      BackgroundColorEnum[controlOptions?.backgroundColor];
    const type = FloatingImageEnum[controlOptions?.type];

    let image;
    blockContent.doQuery((content) => {
      image = content.getFirstOfType(ContentTypeEnum.IMAGE);
    });

    return (
      <FloatingImage
        type={type}
        image={image}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default FloatingImageBlock;
