/* eslint-disable max-classes-per-file */
import { Component } from 'react';
import withAppConfig, {
  WithAppConfigProps,
} from '../../utils/hocs/withAppConfig';
import PageViewInput from '../../utils/reporterInput/PageViewInput';

export abstract class PageViewTrackingBase<P> extends Component<
  WithAppConfigProps & P
> {
  protected _mountTimestamp: number = 0;
  protected _referrer: string = '';
  protected _prevPage: string = '';
  protected _currentPage: string = '';
  protected _exitPage: string = '';
  protected _wasTracked: boolean = false;
  protected _startPage: string = '';

  public componentDidMount() {
    const { appConfig } = this.props;

    this._mountTimestamp = Date.now();

    this._currentPage = appConfig.pageHistory.current;
    this._prevPage = appConfig.pageHistory.previous;
    this._startPage = appConfig.pageHistory.start;
    this._referrer = document.referrer;
    window.addEventListener('beforeunload', () => this.handleExitPage());
  }

  public componentWillUnmount() {
    if (this._mountTimestamp === 0) {
      throw new Error(
        'withPageViewTracking: mountTimestamp is not initialized.',
      );
    }

    this.trackPage();
  }

  private handleExitPage(): void {
    this._exitPage = this.props.appConfig.pageHistory.current;

    if (!this._wasTracked) {
      this.trackPage();
    }
  }

  private trackPage(): void {
    const prevPage = this._prevPage.split('/').slice(3).join('/');
    const currentPage = this._currentPage.split('/').slice(3).join('/');
    const exitPage = this._exitPage.split('/').slice(3).join('/');
    const startPage = this._startPage.split('/').slice(3).join('/');

    const viewPageInput = new PageViewInput(
      this._referrer,
      prevPage,
      currentPage,
      exitPage,
      startPage,
    );
    this.props.appConfig.services.reporterService.trackPageView(viewPageInput);

    this._wasTracked = true;
  }
}

export default function WithPageViewTracking<P>(
  Component: React.ComponentType<P>,
  className?: string,
): React.ComponentClass<P> {
  return class extends PageViewTrackingBase<P> {
    public render() {
      return (
        <div className={className}>
          <Component {...this.props} />
        </div>
      );
    }
  };
}
