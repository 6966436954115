import SubpageGrid from '../../components/SubpageGrid';
import { ContentTypeEnum } from '../../constants/cms-constants';
import { BlockInstanceProps } from '../Block';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import backgroundColors from '../../constants/background-color';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'seoWeight',
    definition: controlOptionDefinitions.SEOWeightTypes,
  },
])
class SubpageGridBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor = backgroundColors[controlOptions?.backgroundColor];
    const seoWeight = controlOptions?.seoWeight;

    let items;
    blockContent.doQuery((content) => {
      items = content.getAllOfType(ContentTypeEnum.MEDIAITEM);
    });

    return (
      <SubpageGrid
        items={items}
        backgroundColor={backgroundColor}
        seoWeight={seoWeight}
        header={block.header.main}
      />
    );
  }
}

export default SubpageGridBlock;
