import { Component } from 'react';
import { css, keyframes } from '@emotion/react';

import Header from '../atoms/Header';
import BodyText from '../atoms/BodyText';
import Hstack3CellExtremity from '../atoms/Hstack3CellExtremity';
import PagedCarouselControl from './PagedCarouselControl';
import InlinedRichText from './InlinedRichText';
import { defaultColors } from '../../../constants/colors';
import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import { BackgroundColorEnum } from '../../../constants/cms-constants';

const animations = {
  headerHighlightUp: keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: -100% 0;
  }
  `,
  headerHighlightDown: keyframes`
   0% {
    background-position: -0% 0;
  }
  100% {
    background-position: -100% 0;
  }
  `,
  headerHighlightLeaveUp: keyframes`
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: -0% 0;
    visibility: hidden;
  }
  `,
  headerHighlightLeaveDown: keyframes`
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: -200% 0;
    visibility: hidden;
  }
  `,
  enterUp: keyframes`
  0% {
    transform: translateY(150%);
  }
  100% {
    transform: translateY(0%);
  }
  `,
  leaveUp: keyframes`
  0% {
    transform: translateY(-108%);
  }
  100% {
    transform: translateY(-250%);
  }
  `,
  enterDown: keyframes`
  0% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateY(0%);
  }
  `,
  leaveDown: keyframes`
  0% {
    transform: translateY(-108%);
  }
  100% {
    transform: translateY(50%);
  }
  `,
  leaveUpDesktop: keyframes`
  0% {
    transform: translateY(-116%);
  }
  100% {
    transform: translateY(-250%);
  }
  `,
  leaveDownDesktop: keyframes`
  0% {
    transform: translateY(-115%);
  }
  100% {
    transform: translateY(50%);
  }
  `,
};

const animationTime = '1300ms';

const styles = css({
  '& .section-title': {
    color: defaultColors.lightHeader,
    zIndex: '2',
  },

  '& .headerText': {
    marginLeft: 'auto',
    width: 'fit-content',
    marginBottom: '8px',
    cursor: 'pointer',
    position: 'relative',
    color: defaultColors.lightHeader,
    '&:focus': {
      outline: 'none',
    },
    '&.activeDown, &.activeUp, &.prevActiveDown, &.prevActiveUp': {
      pointerEvents: 'none',
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        backgroundImage: `linear-gradient(to right, ${BackgroundColorEnum.WHITE} 50%, ${BackgroundColorEnum.BLACK} 50%)`,
        height: '1px',
        width: '100%',
        backgroundPosition: '-100% 0',
        backgroundSize: '200% auto',
      },
    },
    '&.activeDown': {
      '&::before': {
        animation: `${animations.headerHighlightDown} ${animationTime} forwards`,
      },
    },
    '&.activeUp': {
      '&::before': {
        animation: `${animations.headerHighlightUp} ${animationTime} forwards`,
      },
    },
    '&.prevActiveDown': {
      '&::before': {
        animation: `${animations.headerHighlightLeaveDown} ${animationTime} forwards`,
      },
    },
    '&.prevActiveUp': {
      '&::before': {
        animation: `${animations.headerHighlightLeaveUp} ${animationTime} forwards`,
      },
    },
  },
  '& .container': {
    [Breakpoints.DesktopUp.mq]: {
      paddingRight: '300px',
      height: '320px',
      marginTop: '-80px',
    },
    [Breakpoints.Tablet.mq]: {
      paddingRight: '150px',
      height: '300px',
      marginTop: '-80px',
    },
    [Breakpoints.Mobile.mq]: {
      height: '260px',
    },
    overflow: 'hidden',
    '& .cur-container-up, .cur-container-down, .prev-container-up, .prev-container-down':
      {
        height: '100%',
        [Breakpoints.DesktopUp.mq]: {
          paddingLeft: '100px',
        },
        [Breakpoints.TabletUp.mq]: {
          textAlign: 'left',
        },
        '& .cur-title-container, .cur-body-container, .prev-title-container, .prev-body-container':
          {
            marginTop: '20px',
            marginBottom: '20px',
            '& p': {
              marginBottom: 0,
            },
            '& ul': {
              paddingInlineStart: '28px',
              WebkitPaddingStart: '28px',
            },
          },
      },
    '& .cur-container-up': {
      animation: `${animations.enterUp} ${animationTime} forwards ease`,
    },
    '& .prev-container-up': {
      animation: `${animations.leaveUp} ${animationTime} forwards ease`,
      [Breakpoints.DesktopUp.mq]: {
        animation: `${animations.leaveUpDesktop} ${animationTime} forwards ease`,
      },
    },
    '& .cur-container-down': {
      animation: `${animations.enterDown} ${animationTime} forwards ease`,
    },
    '& .prev-container-down': {
      animation: `${animations.leaveDown} ${animationTime} forwards ease`,
      [Breakpoints.DesktopUp.mq]: {
        animation: `${animations.leaveDownDesktop} ${animationTime} forwards ease`,
      },
    },
  },
  [Breakpoints.Mobile.mq]: {
    '& .current-value-indicator': {
      '& .item-right': {
        display: 'none',
      },
      '& .item-left': {
        position: 'absolute',
      },
    },
    '& .bottom-controller .item-right': {
      display: 'none',
    },
    '& .top-controller .item-left': {
      display: 'none',
    },
  },
  [Breakpoints.TabletUp.mq]: {
    '& .top-controller': {
      display: 'none',
    },
  },
});

type CarouselProps = {
  items?: InternalPropTypes.MediaItem[];
  lightHeader?: InternalPropTypes.RichText;
};
interface ICarouselState {
  index: number;
  prevIndex: number;
}

class Carousel extends Component<CarouselProps, ICarouselState> {
  static defaultProps = {
    items: [],
    lightHeader: null,
  };

  private animation: string;

  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      prevIndex: 4,
    };
    this._onChangeIndex = this._onChangeIndex.bind(this);
  }

  getAnimationClass(actualPrevIndex, currentIndex) {
    const { index, prevIndex } = this.state;
    const maxIndex = this.props.items.length - 1;
    switch (currentIndex) {
      case index:
        if (index - prevIndex === -maxIndex) {
          return 'activeDown';
        }
        if (index - prevIndex === maxIndex) {
          return 'activeUp';
        }
        if (index - prevIndex < 0) {
          return 'activeUp';
        }
        if (index - prevIndex > 0) {
          return 'activeDown';
        }
        return '';
      case actualPrevIndex:
        if (index - prevIndex === -maxIndex) {
          return 'prevActiveDown';
        }
        if (index - prevIndex === maxIndex) {
          return 'prevActiveUp';
        }
        if (index - prevIndex < 0) {
          return 'prevActiveUp';
        }
        if (index - prevIndex > 0) {
          return 'prevActiveDown';
        }
        return '';
      default:
        return '';
    }
  }

  _setIndex = (newIdx, prevIdx) => {
    if (newIdx !== prevIdx) {
      this.setState({
        index: newIdx,
        prevIndex: prevIdx,
      });
    }
  };

  _onChangeIndex(newIdx, preIdx) {
    this.setState({
      index: newIdx - 1,
      prevIndex: preIdx - 1,
    });
  }

  render() {
    const { items, lightHeader } = this.props;
    const { index, prevIndex } = this.state;
    const stateLoaded = typeof index === 'number';

    if (
      !this.animation ||
      index > prevIndex ||
      (index === 0 && prevIndex === items.length - 1)
    ) {
      if (index === 4 && prevIndex === 0) {
        this.animation = 'down';
      } else {
        this.animation = 'up';
      }
    } else {
      this.animation = 'down';
    }

    return stateLoaded ? (
      <div className="value-carousel-molecule" css={styles}>
        <div className="current-value-indicator">
          <Hstack3CellExtremity
            onRenderLeftCell={() => (
              <BodyText
                type={BodyText.types.STATIC16}
                className="section-title"
              >
                {lightHeader}
              </BodyText>
            )}
            onRenderRightCell={() => (
              <div className="headersCol">
                <div className="headers">
                  {items.map((mediaItem, idx) => (
                    // eslint-disable-next-line react/no-array-index-key, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                    <div key={idx} onClick={() => this._setIndex(idx, index)}>
                      <BodyText
                        type={BodyText.types.LIGHT14}
                        className={`headerText
                                     ${this.getAnimationClass(prevIndex, idx)}`}
                      >
                        {mediaItem.header}
                      </BodyText>
                    </div>
                  ))}
                </div>
              </div>
            )}
          />
        </div>

        <div className="top-controller">
          <PagedCarouselControl
            currentIndex={(index + 1) % (items.length + 1)}
            maxIndex={items.length}
            onChangeIndex={this._onChangeIndex}
          />
        </div>

        <div className="container">
          <div className={`cur-container-${this.animation}`} key={index}>
            <div className="cur-title-container">
              <Header seoWeight="h2" type={Header.types.VALUES}>
                {items[index].header}
              </Header>

              <div className="cur-body-container">
                <InlinedRichText richText={items[index].bodyText} />
              </div>
            </div>
          </div>

          <div className={`prev-container-${this.animation}`} key={prevIndex}>
            <div className="prev-title-container">
              <Header type={Header.types.VALUES}>
                {items[prevIndex].header}
              </Header>

              <div className="prev-body-container">
                <InlinedRichText richText={items[prevIndex].bodyText} />
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-controller">
          <PagedCarouselControl
            currentIndex={(index + 1) % (items.length + 1)}
            maxIndex={items.length}
            onChangeIndex={this._onChangeIndex}
          />
        </div>
      </div>
    ) : null;
  }
}

export default Carousel;
