import { BlockInstanceProps } from '../Block';
import CaseHeroComponent from '../../components/CaseHero';
import {
  ContentTypeEnum,
  BackgroundColorEnum,
} from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class CaseHero extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor =
      BackgroundColorEnum[controlOptions?.backgroundColor];

    let mediaItem;
    blockContent.doQuery((content) => {
      mediaItem = content.getFirstOfType(ContentTypeEnum.MEDIAITEM);
    });

    return (
      <CaseHeroComponent
        mediaItem={mediaItem}
        header={block?.header?.main}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default CaseHero;
