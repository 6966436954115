export enum SeverityLevels {
  VERBOSE = 0,
  INFORMATION = 1,
  WARNING = 2,
  ERROR = 3,
  CRITICAL = 4,
}

export interface IErrorInput {
  name: string;
  message: string;
  exception?: Error;
  severityLevel?: SeverityLevels;
  stack?: string;
  properties?: { [key: string]: any };
}

export interface IEventInput {
  name: string;
  properties?: { [key: string]: any };
}

export interface IRequestInput {
  readonly name: string;
  readonly url: string;
  readonly duration: number;
  readonly resultCode: string | number;
  readonly success: boolean;
  source?: string;
  time?: Date;
  properties?: { [key: string]: any };
  tagOverrides?: { [key: string]: string };
  measurements?: { [key: string]: number };
  contextObjects?: { [key: string]: any };
}

export interface IDepdendencyInput {
  dependencyTypeName: string;
  target?: string;
  name: string;
  data: string;
  startTime: Date;
  duration: number;
  resultCode: string | number;
  success: boolean;
  properties?: { [key: string]: any };
}

export interface IPageViewInput {
  referrer: string;
  prevPage: string;
  currentPage: string;
  exitPage: string;
  startPage: string;
}
