const EmbeddedEntries = [
  'CueCardGridBlock',
  'FactBoxBlock',
  'QuoteBlock',
  'OEmbedding',
  'WellFullSizeBlock',
  'WellGridBlock',
  'FloatingImageBlock',
];

export default EmbeddedEntries;
