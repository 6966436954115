const ContentTypes = {
  SNIPPET: 'snippet',
  IMAGE: 'image',
  VIDEO: 'video',
  PERSON: 'person',
  MEDIAITEM: 'mediaItem',
  LINK: 'link',
  CONTROLOPTION: 'controlOption',
  BLOCK: 'block',
  PAGE: 'page',
  ROOT: 'root',
  EVENT: 'event',
  MAILCONTENT: 'mailContent',
  TECHNICALCONFIGURATION: 'configuration',
};

module.exports = ContentTypes;
