import { Subject, Subscription } from 'rxjs';
import emailTypes from '../../server-utils/express-middleware/email-middleware/email-types';
import { filterPersonalInformation } from '../../utils/filterContactInformation';
import DependencyInput from '../../utils/reporterInput/DependencyInput';
import BaseConnector from './BaseConnector';
import EventInput from '../../utils/reporterInput/EventInput';

function submitToMailEndpoint(body: object) {
  const url =
    typeof window !== 'undefined' ? `${window.location.origin}/mail` : '';
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
}

function resetCache() {
  const url =
    typeof window !== 'undefined'
      ? `${window.location.origin}/reset-cache`
      : '';
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

type SubmitFunction = (...args) => Promise<Response>;

const SubmitFunctions: Record<string, SubmitFunction> = {
  submitToMailEndpoint,
  resetCache,
};

export default class SelfConnector extends BaseConnector {
  eventSubject = new Subject<EventInput>();

  constructor() {
    super();
    this.submitContactForm = this.submitContactForm.bind(this);
    this.submitBookingForm = this.submitBookingForm.bind(this);
    this.submitFileRequest = this.submitFileRequest.bind(this);
  }

  submitToEvent(onEvent: (eventInput: EventInput) => void): Subscription {
    return this.eventSubject.asObservable().subscribe(onEvent);
  }

  // eslint-disable-next-line class-methods-use-this
  call(submit: SubmitFunction, submitInput?): Promise<Response> {
    const start = new Date();
    const promise = submit(submitInput);
    promise.then((response) => {
      let properties: object;
      const duration = new Date().getTime() - start.getTime();
      const type = 'HTTP';
      const method = 'POST';
      const data = response.url;
      const name = [method, data].join(' ');

      if (submitInput) {
        properties = { body: filterPersonalInformation(submitInput) };
      }

      const dependencyInput = new DependencyInput(
        type,
        name,
        data,
        start,
        duration,
        response.ok,
        response.status,
        data,
        properties,
      );

      if (submitInput) {
        const eventInput = new EventInput(
          `Submit form: ${submitInput.emailType}`,
          filterPersonalInformation(submitInput),
        );
        this.eventSubject.next(eventInput);
      }
      this.afterFetch(dependencyInput);
    });
    return promise;
  }

  submitContactForm(name, email, msg) {
    return this.call(SubmitFunctions.submitToMailEndpoint, {
      mailTo: process.env.RAZZLE_MAIL_SEND_TO_ADDRESS,
      emailType: emailTypes.CONTACT,
      name,
      email,
      msg,
    });
  }

  submitBookingForm(name, email, date, participants) {
    return this.call(SubmitFunctions.submitToMailEndpoint, {
      mailTo: process.env.RAZZLE_MAIL_SEND_TO_ADDRESS,
      emailType: emailTypes.BOOK,
      name,
      email,
      date,
      participants,
    });
  }

  submitFileRequest(name, email, links, mailContent) {
    return this.call(SubmitFunctions.submitToMailEndpoint, {
      mailTo: email,
      emailType: emailTypes.DOWNLOADLINK,
      name,
      email,
      links,
      mailContent,
    });
  }

  resetCache() {
    return this.call(SubmitFunctions.resetCache);
  }
}
