import { Component } from 'react';
import { keyframes, css } from '@emotion/react';
import {
  TypeBackgroundColorTypes,
  BackgroundColorEnum,
} from '../../../constants/cms-constants';

const animation = {
  slideIn: keyframes`
    from {
      transform: translateY(100%)
    }
    to {
      transform: translateY(0)
    }
  `,
  slideOut: keyframes`
    from {
      transform: translateY(0)
    }
    to {
      transform: translateY(100%)
    }
  `,
};

const animationTime = 700;

const useStyles = css({
  height: 'auto',
  width: '100%',
  position: 'fixed',
  zIndex: '1002',
  bottom: 0,
  left: 0,
  '&.modal-overlay-opening': {
    animation: `${animation.slideIn} ${animationTime}ms forwards ease-in`,
  },
  '&.modal-overlay-closing': {
    animation: `${animation.slideOut} ${animationTime}ms forwards ease-in`,
  },
});

type BottomPanelProps = {
  visible?: boolean;
  backgroundColor?: TypeBackgroundColorTypes;
  children?: React.ReactNode;
};

class BottomPanel extends Component<BottomPanelProps> {
  static defaultProps = {
    visible: false,
    children: null,
    backgroundColor: BackgroundColorEnum.BLACK,
  };

  render() {
    const { visible, backgroundColor, children } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div
        className={`modal-overlay-${visible ? 'opening' : 'closing'}`}
        css={[useStyles, addStyle]}
      >
        {children}
      </div>
    );
  }
}

export default BottomPanel;
