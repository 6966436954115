import { Component } from 'react';
import { css } from '@emotion/react';

import BodyText from '../atoms/BodyText';
import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import {
  fontColorEnum,
  QuoteTypeEnum,
  TypeQuoteTypes,
} from '../../../constants/cms-constants';

const styles = css({
  '& .sender': {
    color: fontColorEnum.BLUE,
  },
  '& .single-quote': {
    [Breakpoints.DesktopUp.mq]: {
      minHeight: '300px',
      paddingBottom: '2em',
    },
    [Breakpoints.Tablet.mq]: {
      paddingBottom: '2em',
      minHeight: '180px',
    },
    [Breakpoints.Mobile.mq]: {
      minHeight: '240px',
      paddingBottom: '1em',
    },
    '& .sender': {
      marginTop: '16px',
    },
    '& .quote': {
      paddingTop: '16px',
      [Breakpoints.TabletUp.mq]: {
        paddingRight: '100px',
      },
      [Breakpoints.Mobile.mq]: {
        paddingRight: '0',
      },
    },
  },
  '& .block-quote': {
    marginRight: 'auto',
    marginLeft: 'auto',
    textAlign: 'center',
    [Breakpoints.DesktopUp.mq]: {
      maxWidth: '554px',
    },
    [Breakpoints.Tablet.mq]: {
      maxWidth: '554px',
    },
    [Breakpoints.Mobile.mq]: {
      maxWidth: '343px',
    },
    '& .quote': {
      marginBottom: '30px',
    },
  },
});

type QuoteProps = {
  type?: TypeQuoteTypes;
  quote?: InternalPropTypes.RichText;
  sender?: string | InternalPropTypes.RichText;
};

class Quote extends Component<QuoteProps> {
  static types = QuoteTypeEnum;

  static defaultProps = {
    type: this.types.SINGLE,
    quote: null,
    sender: null,
  };

  render() {
    const { type, quote, sender } = this.props;
    return (
      <div css={styles}>
        <div className={`${type}-quote`}>
          <BodyText
            className="quote"
            type={
              type === 'single'
                ? BodyText.types.QUOTE
                : BodyText.types.QUOTEBLOCK
            }
          >
            {quote}
          </BodyText>
          <BodyText
            className="sender"
            type={
              type === 'single' ? BodyText.types.BOLD16 : BodyText.types.BOLD12
            }
          >
            {sender}
          </BodyText>
        </div>
      </div>
    );
  }
}

export default Quote;
