import EventUpComing from '../../components/EventUpComing';
import { BlockInstanceProps } from '../Block';
import {
  ContentTypeEnum,
  BackgroundColorEnum,
} from '../../constants/cms-constants';
import { getComingEvents } from '../../utils/content/Events';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'seoWeight',
    definition: controlOptionDefinitions.SEOWeightTypes,
  },
])
class EventUpComingBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor =
      BackgroundColorEnum[controlOptions?.backgroundColor];
    const seoWeight = controlOptions?.seoWeight;

    let events;

    blockContent.doQuery((content) => {
      events = content.getAllOfType(ContentTypeEnum.EVENT);
    });
    getComingEvents(events);

    return (
      <EventUpComing
        mainHeader={block?.header?.main}
        events={events}
        backgroundColor={backgroundColor}
        seoWeight={seoWeight}
      />
    );
  }
}

export default EventUpComingBlock;
