import CallToActionWithHeader from '../../components/CallToActionWithHeader';
import { BlockInstanceProps } from '../Block';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import {
  CallToActionTypeEnum,
  CallToActionWithAlignmentTypeEnum,
  BackgroundColorEnum,
} from '../../constants/cms-constants';

@ControlOption([
  {
    key: 'alignment',
    definition: controlOptionDefinitions.callToActionWithAlignmentTypes,
  },
  { key: 'ctaType', definition: controlOptionDefinitions.callToActionTypes },
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class CallToActionWithHeaderBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  static types = CallToActionWithHeader.types;

  static ctaTypes = CallToActionWithHeader.ctaTypes;

  render() {
    const { block } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor =
      BackgroundColorEnum[controlOptions?.backgroundColor];
    const type = CallToActionWithAlignmentTypeEnum[controlOptions?.type];
    const ctaType = CallToActionTypeEnum[controlOptions?.ctaType];
    return (
      <CallToActionWithHeader
        header={block?.header?.main}
        link={block?.link}
        type={type}
        ctaType={ctaType}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default CallToActionWithHeaderBlock;
