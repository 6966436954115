import { Component } from 'react';

import * as InternalPropTypes from '../constants/internal-types';
import FloatingImageMolecule from './common/molecules/FloatingImage';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import {
  TypeBackgroundColorTypes,
  TypeFloatingImageTypes,
} from '../constants/cms-constants';

type FloatingImageProp = {
  type?: TypeFloatingImageTypes;
  image?: InternalPropTypes.Image;
  backgroundColor?: TypeBackgroundColorTypes;
};

class FloatingImage extends Component<FloatingImageProp> {
  static types = FloatingImageMolecule.types;
  static defaultProps = {
    type: this.types.LEFT,
    image: null,
    backgroundColor: null,
  };

  render() {
    const { type, image, backgroundColor } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div css={[addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <FloatingImageMolecule type={type} image={image} />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default FloatingImage;
