import { Component } from 'react';

import RichTextWrapper from '../RichTextWrapper';
import RichTextRenderer from '../../../utils/content/RichTextRenderer';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import { defaultColors } from '../../../constants/colors';
import font from '../../../constants/fonts';

const styles = {
  padding: '0',
  margin: '0',
  color: defaultColors.bodyText,
  ...font.GNBook,
  '&.landing-hero': {
    ...font.GNLight,
    [Breakpoints.TabletDown.mq]: {
      fontSize: '16px',
    },
    [Breakpoints.DesktopUp.mq]: {
      fontSize: '18px',
    },
  },
  '&.static16': {
    fontSize: '16px',
    lineHeight: '21px',
  },
  '&.static16-article': {
    lineHeight: '24px',
  },
  '&.bold16': {
    ...font.GNBold,
    fontSize: '16px',
  },
  '&.static14': {
    fontSize: '14px',
    lineHeight: '18px',
  },
  '&.medium14': {
    ...font.GNMedium,
    fontSize: '14px',
    lineHeight: '18px',
  },
  '&.bold14': {
    ...font.GNBold,
    fontSize: '14px',
    lineHeight: '18px',
  },
  '&.light14': {
    ...font.GNLight,
    fontSize: '14px',
  },
  '&.static12': {
    fontSize: '12px',
    lineHeight: '18px',
  },
  '&.bold12': {
    ...font.GNBold,
    fontSize: '12px',
    lineHeight: '18px',
  },
  '&.book12': {
    ...font.GNBook,
    fontSize: '12px',
  },
  '&.medium12': {
    ...font.GNMedium,
    fontSize: '12px',
  },
  '&.light12': {
    ...font.GNLight,
    fontSize: '12px',
  },
  '&.quote': {
    ...font.GNBold,
    [Breakpoints.DesktopUp.mq]: {
      fontSize: '32px',
      lineHeight: '44px',
      letterSpacing: '-0.75px',
    },
    [Breakpoints.Tablet.mq]: {
      fontSize: '32px',
      lineHeight: '44px',
      letterSpacing: '-0.75px',
    },
    [Breakpoints.Mobile.mq]: {
      fontSize: '18px',
      lineHeight: '22px',
      letterSpacing: '-0.39px',
    },
  },
  '&.quote-block': {
    ...font.GNBook,
    [Breakpoints.TabletUp.mq]: {
      fontSize: '32px',
      lineHeight: '38px',
      letterSpacing: '-0.25px',
    },
    [Breakpoints.Mobile.mq]: {
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '-0.16px',
    },
  },
  '&.enquiry-case': {
    ...font.GNLight,
    lineHeight: '21px',
    fontSize: '16px',
  },
  '&.enquiry-contact': {
    ...font.GNMedium,
    fontSize: '14px',
    lineHeight: '18px',
  },
  '&.list-group': {
    ...font.GNBook,
    [Breakpoints.DesktopUp.mq]: {
      fontSize: '24px',
      lineHeight: '28px',
      letterSpacing: '-0.2px',
    },
    [Breakpoints.Tablet.mq]: {
      fontSize: '19px',
      lineHeight: '22px',
      letterSpacing: '-0.14px',
    },
    [Breakpoints.Mobile.mq]: {
      fontSize: '19px',
      lineHeight: '22px',
      letterSpacing: '-0.14px',
    },
  },
  '&.list-group-bold': {
    ...font.GNBold,
    fontSize: '12px',
    lineHeight: '18px',
  },
  '&.header-2-text-group': {
    ...font.GNBook,
    lineHeight: '28px',
    [Breakpoints.TabletUp.mq]: {
      fontSize: '24px',
      letterSpacing: '-0.2px',
    },
    [Breakpoints.Mobile.mq]: {
      fontSize: '21px',
      letterSpacing: '-0.15px',
    },
  },
  '&.article': {
    ...font.GNBook,
    fontSize: '21px',
    lineHeight: '24px',
  },
  '&.list-group-link-1': {
    ...font.GNBook,
    [Breakpoints.Mobile.mq]: {
      fontSize: '21px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '18px',
    },
  },
  '&.contact-card': {
    ...font.GNBook,
    fontSize: '32px',
    letterSpacing: '-0.27px',
    lightHeight: '32px',
  },
  '&.contact-card-bold': {
    ...font.GNBold,
    fontSize: '32px',
    letterSpacing: '-0.27px',
    lightHeight: '32px',
  },
};

enum BodyTextType {
  HERO = 'hero',
  LANDINGHERO = 'landing-hero',
  STATIC16 = 'static16',
  STATIC16ARTICLE = 'static16-article',
  BOLD16 = 'bold16',
  BOLD14 = 'bold14',
  LIGHT14 = 'light14',
  BOLD12 = 'bold12',
  LIGHT12 = 'light12',
  QUOTE = 'quote',
  QUOTEBLOCK = 'quote-block',
  HEADER2TEXTGROUP = 'header-2-text-group',
  ARTICLE = 'article',
  CONTACTCARD = 'contact-card',
  CONTACTCARDBOLD = 'contact-card-bold',
}

type BodyTextProps = {
  type?: BodyTextType;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => any;
  as?: React.ElementType;
  children?: InternalPropTypes.StringOrRichTextOrElementOrNestedArrays;
};

class BodyText extends Component<BodyTextProps> {
  static types = BodyTextType;

  static defaultProps = {
    type: BodyTextType.HERO,
    className: null,
    as: 'p',
    children: null,
    onClick: () => null,
  };

  render() {
    const { children, type, className, onClick, as, ...rest } = this.props;

    const Elem = as;
    let output = children;
    if (InternalPropTypes.isRichText(children)) {
      // children is rich text, do a default flatten without any other markup
      output = (
        <RichTextWrapper
          richText={children as InternalPropTypes.RichText}
          renderFunction={RichTextRenderer.formattedTextAndEmbeddedEntries}
        />
      );
    }
    return (
      <Elem
        css={styles}
        onClick={onClick}
        className={`body-text ${type}${className ? ` ${className}` : ''}`}
        {...rest}
      >
        {output}
      </Elem>
    );
  }
}

export default BodyText;
