import { Component } from 'react';

import SubpageGridMolecules from './common/molecules/SubpageGrid';

import * as InternalPropTypes from '../constants/internal-types';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';

import SEOWeights from '../constants/cms/control-options/seo-weights';
import {
  TypeBackgroundColorTypes,
  TypeSEOWeightTypes,
} from '../constants/cms-constants';

type SubpageGridProps = {
  header?: InternalPropTypes.RichText;
  items?: InternalPropTypes.MediaItem[];
  backgroundColor?: TypeBackgroundColorTypes;
  seoWeight?: TypeSEOWeightTypes;
};

class SubpageGrid extends Component<SubpageGridProps> {
  static defaultProps = {
    header: null,
    items: [],
    backgroundColor: null,
    seoWeight: SEOWeights.H2,
  };

  render() {
    const { header, items, backgroundColor, seoWeight } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div css={addStyle} className="subpage-grid">
        <ContentOuterBound>
          <ContentSpacer>
            <SubpageGridMolecules
              header={header}
              items={items}
              seoWeight={seoWeight}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default SubpageGrid;
