import { ClientConfig } from '../StaticConfig';
import ConsoleLoggerDriver from './ConsoleLoggerDriver';
import ClientApplicationInsightsDriver from './ClientApplicationInsightsDriver';
import ClientPlausibleDriver from './ClientPlausibleDriver';
import Consent from '../../utils/Consent';

export default class ClientDriverFactory {
  static getDrivers(staticConfig: ClientConfig, consent: Consent) {
    return [
      new ClientApplicationInsightsDriver(staticConfig, consent),
      new ConsoleLoggerDriver(staticConfig),
      new ClientPlausibleDriver(staticConfig),
    ];
  }
}
