import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { css } from '@emotion/react';
import { Button, Modal, Tabs } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Badge from 'react-bootstrap/Badge';

import SeoAccordion from './SeoAccordion';

import LoadingListener, {
  LoadingListenerProps,
  LoadingListenerState,
} from '../../../containers/LoadingListener';
import { isPageCacheKey } from '../../../containers/Page';
import { isRootCacheKey } from '../../../containers/Root';

import seoIcon from '../../../assets/seo-icon.png';

import withLoadingContext from '../../../utils/hocs/withLoadingContext';
import withAppConfig, {
  WithAppConfigProps,
} from '../../../utils/hocs/withAppConfig';
import PageTitleHeuristic from '../../../utils/heuristics/PageTitleHeuristic';
import PageDescriptionHeuristic from '../../../utils/heuristics/PageDescriptionHeuristic';
import PageImageHeuristic from '../../../utils/heuristics/PageImageHeuristic';
import PageSharingTwitterHeuristic from '../../../utils/heuristics/PageSharingTwitterHeuristic';
import PageSharingOpenGraphHeuristic from '../../../utils/heuristics/PageSharingOpenGraphHeuristic';
import 'core-js/es/promise';
import PageAlternateHeuristic from '../../../utils/heuristics/PageAlternateHeuristic';
import {
  Heuristics,
  HeuristicsResponseType,
} from '../../../utils/heuristics/Heuristics';
import { LoadResult } from '../../../client-server-utils/loading/LoadingManager';

import * as seoStatus from '../../../constants/seoStatus';
import { Page, Root } from '../../../constants/internal-types';
import withImageHelperFactory, {
  WithImageHelperFactoryProps,
} from '../../../utils/hocs/withImageHelperFactory';
import {
  BackgroundColorEnum,
  fontColorEnum,
} from '../../../constants/cms-constants';
import { colorEnum } from '../../../constants/colors';

const useStyles = css({
  width: '100%',
  '.seo-inspector-button': {
    zIndex: 1003,
    backgroundColor: BackgroundColorEnum.WHITE,
    position: 'relative',
    opacity: 0.3,
    transition: 'opacity .2s ease-in-out, background-color .2s ease-in-out',
    '&.btn-primary': {
      color: fontColorEnum.BLACK,
      borderColor: BackgroundColorEnum.WHITE,
    },
    '& img': {
      height: '20px',
      width: '20px',
    },
    '&.open': {
      backgroundColor: BackgroundColorEnum.BLUE,
      borderColor: BackgroundColorEnum.BLUE,
      '& img': {
        filter: 'invert(1)',
      },
    },
    '&.seo-warning': {
      opacity: 0.7,
      backgroundColor: colorEnum.orange,
    },
    '&.seo-error': {
      opacity: 0.7,
      backgroundColor: colorEnum.red,
    },
    '&:hover': {
      opacity: 1,
    },
  },
  '.heuristics-loading-screen': {
    textAlign: 'center',
  },
});

const modalStyles = css({
  '.seoModalSubTitle': {
    textAlign: 'center',
    paddingTop: '20px',
  },
  '.accordion-indicator': {
    float: 'inline-end',
  },
  '.modal-dialog': {
    maxWidth: '80%',
    zIndex: 1003,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
});

function countStatuses(
  status: seoStatus.SeoStatusType,
  section: HeuristicsResponseType[],
) {
  return section ? section.filter((r) => r.status === status).length : 0;
}

function HeuristicBadge({
  responses,
}: {
  responses: HeuristicsResponseType[];
}) {
  const errors = countStatuses(seoStatus.STATUS_ERROR, responses);
  const warnings = countStatuses(seoStatus.STATUS_WARNING, responses);

  // eslint-disable-next-line no-nested-ternary
  return errors > 0 ? (
    <Badge variant="danger">{errors}</Badge>
  ) : warnings > 0 ? (
    <Badge variant="warning">{warnings}</Badge>
  ) : (
    <Badge variant={responses.length > 0 ? 'light' : 'success'}>
      {responses.length}
    </Badge>
  );
}

const HeuristicsConfiguration = {
  'Core Meta': {
    heuristics: [
      PageTitleHeuristic,
      PageDescriptionHeuristic,
      PageAlternateHeuristic,
    ],
  },
  Content: {
    heuristics: [PageImageHeuristic],
  },
  Sharing: {
    heuristics: [PageSharingTwitterHeuristic, PageSharingOpenGraphHeuristic],
  },
};

type SeoInspectorProps = LoadingListenerProps &
  WithAppConfigProps &
  WithImageHelperFactoryProps;

type HeuristicsType = Record<string, { instances: Array<Heuristics> }>;

type SeoInspectorState = LoadingListenerState & {
  modalIsOpen: boolean;
  heuristics: HeuristicsType;
  isMounted: boolean;
  responses: Record<string, Array<HeuristicsResponseType>>;
  isRunningHeuristics: boolean;
  root: Root;
  currentPage: Page;
};

class SeoInspector extends LoadingListener<
  SeoInspectorProps,
  SeoInspectorState
> {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      ...this.state,
      modalIsOpen: false,
      //      heuristics: {},
      isMounted: false,
      responses: {},
      isRunningHeuristics: false,
    };
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    /*
    for (const sectionName in HeuristicsConfiguration) {
      // eslint-disable-next-line no-multi-assign
      this.state.heuristics[sectionName] = { 
        instances: HeuristicsConfiguration[sectionName].heuristics.map(
          (Cls) => new Cls(props.appConfig.settings)
        )
      };
    }
*/
  }

  createHeuristics(): HeuristicsType {
    const { appConfig, imageHelperFactory } = this.props;
    const heuristics: HeuristicsType = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const sectionName in HeuristicsConfiguration) {
      // eslint-disable-next-line no-multi-assign
      heuristics[sectionName] = {
        instances: HeuristicsConfiguration[sectionName].heuristics.map(
          (Cls) => new Cls(appConfig.settings, imageHelperFactory),
        ),
      };
    }
    return heuristics;
  }

  /*
  checkSection(sectionName, page, root) {
    const promises = this.state.heuristics[sectionName].instances.map((heuristic) => heuristic.checkHeuristics(page, root));
    return new Promise((resolve, reject) => {
      Promise.all(promises).then(heuristicResults => resolve(heuristicResults.flat())).catch(reject);
    });
  }
*/
  /**
   * This method will receive data from every call to the cache after the hydration and check if this is page data.
   * If page data is found it will map it to known types used throughout the solution.
   *
   * @param {*} data Information received from the call to cache
   */
  onLoadResult(loadResult: LoadResult): void {
    if (isRootCacheKey(loadResult.key)) {
      this.setState({ root: loadResult.response });
    } else if (isPageCacheKey(loadResult.key)) {
      const page = loadResult.response as Page;
      this.setState({ currentPage: page });
    }
  }

  toggleModal() {
    const currentModalState = this.state.modalIsOpen;
    this.setState({ modalIsOpen: !currentModalState });
  }

  componentDidMount(...args) {
    super.componentDidMount.apply(this, args);
    this.setState({ isMounted: true });
    this._updateHeuristics(true);
  }

  _updateHeuristics(isMounted = false) {
    if (this.state.isMounted || isMounted) {
      const { currentPage, root } = this.state;
      const responses = {};
      this.setState({ isRunningHeuristics: true, responses: {} });
      const heuristics = this.createHeuristics();
      const promises = Object.keys(HeuristicsConfiguration).map((sectionName) =>
        new Promise((resolve, reject) => {
          const promises = heuristics[sectionName].instances.map((heuristic) =>
            heuristic.checkHeuristics(currentPage, root),
          );
          Promise.all(promises)
            .then((heuristicResults) => resolve(heuristicResults.flat()))
            .catch((e) => {
              console.warn('An error occurred during seo inspection:', e);
              reject(e);
            });
        }).then((result) => {
          responses[sectionName] = result;
        }),
      );
      Promise.allSettled(promises).then(() =>
        this.setState({ responses, isRunningHeuristics: false }),
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.isMounted &&
      (this.state.currentPage !== prevState.currentPage ||
        this.state.root !== prevState.root)
    ) {
      // loaded data was changed, time to update the responses from all heuristics
      this._updateHeuristics();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  countStatuses(status, section) {
    return section ? section.filter((r) => r.status === status).length : '';
  }

  render() {
    const { responses, modalIsOpen, isRunningHeuristics } = this.state;
    // eslint-disable-next-line no-unused-vars
    const aggregatedStatus = Object.values(responses)
      .flat()
      .reduce((acc, response) => {
        acc[response.status] = (acc[response.status] || 0) + 1;
        return acc;
      }, {});
    const hasErrors = !!aggregatedStatus[seoStatus.STATUS_ERROR];
    const hasWarning = !!aggregatedStatus[seoStatus.STATUS_WARNING];

    const buttonTitle = `SEO Inspector${
      hasErrors
        ? `. ${aggregatedStatus[seoStatus.STATUS_ERROR]} error${
            aggregatedStatus[seoStatus.STATUS_ERROR] > 1 ? 's' : ''
          }`
        : ''
    }${
      hasWarning
        ? `. ${aggregatedStatus[seoStatus.STATUS_WARNING]} warning${
            aggregatedStatus[seoStatus.STATUS_WARNING] > 1 ? 's' : ''
          }`
        : ''
    }`;

    const focusResponses =
      'Content' in responses
        ? Object.values(responses)
            .flatMap((x) => x)
            .filter((r) => r.status !== seoStatus.STATUS_OKAY)
        : [];

    return (
      <div css={useStyles}>
        <Button
          size="sm"
          className={`seo-inspector-button${hasErrors ? ' seo-error' : ''}${
            hasWarning ? ' seo-warning' : ''
          }${modalIsOpen ? ' open' : ''}`}
          onClick={this.toggleModal}
          title={buttonTitle}
        >
          {isRunningHeuristics ? (
            <Spinner
              as="span"
              size="sm"
              aria-hidden="true"
              animation="border"
              role="status"
            >
              <span className="sr-only">Loading heuristics responses</span>
            </Spinner>
          ) : (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img src={seoIcon} />
          )}
        </Button>
        <Modal
          show={modalIsOpen}
          onHide={this.toggleModal}
          backdrop="static"
          css={modalStyles}
        >
          <Modal.Header closeButton>
            <Modal.Title>Seo Inspector</Modal.Title>
          </Modal.Header>
          <Modal.Body className="seoModalBody">
            {isRunningHeuristics ? (
              <div className="heuristics-loading-screen">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading heuristics responses</span>
                </Spinner>
              </div>
            ) : (
              <Tabs>
                <Tab
                  eventKey="focus"
                  title={
                    <span>
                      Focus <HeuristicBadge responses={focusResponses} />
                    </span>
                  }
                >
                  <SeoAccordion heuristicResponses={focusResponses} />
                </Tab>
                {Object.keys(HeuristicsConfiguration)
                  .filter((prop) => prop in responses)
                  .map((sectionName) => (
                    <Tab
                      key={sectionName}
                      eventKey={sectionName}
                      title={
                        <span>
                          {sectionName}{' '}
                          <HeuristicBadge responses={responses[sectionName]} />
                        </span>
                      }
                    >
                      <SeoAccordion
                        heuristicResponses={responses[sectionName]}
                      />
                    </Tab>
                  ))}
              </Tabs>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default compose<SeoInspectorProps, Record<string, never>>(
  withImageHelperFactory,
  withAppConfig,
  withRouter,
  withLoadingContext,
)(SeoInspector);
