import React, { Component } from 'react';
import { keyframes, css } from '@emotion/react';

import angledArrow from '../../../assets/angledArrow.png';
import Caption from '../atoms/Caption';
import NwLink from '../NwLink';

import { changeHashToQuery } from '../../../utils/url';

import { defaultColors } from '../../../constants/colors';
import * as InternalPropTypes from '../../../constants/internal-types';
import {
  CallToActionTypeEnum,
  TypeCallToActionTypes,
  BackgroundColorEnum,
  fontColorEnum,
} from '../../../constants/cms-constants';
import Image from '../atoms/Image';

const animations = {
  hover: keyframes`
    100% {
      transform: skew(-20deg);
     }
  `,
  evenOutHover: keyframes`
    100% {
      transform: skew(20deg);
     }
  `,
};

const useStyles = css({
  '&.regular, &.central, &.left-pointing, &.large, &.signup-event': {
    backgroundImage: `linear-gradient(to right, ${BackgroundColorEnum.WHITE} 50%, ${defaultColors.linkHover} 50%)`,
    backgroundPosition: '-0% 0',
    backgroundSize: '200% auto',
    transition: 'background-position 0.3s ease-out',
    width: 'fit-content',
    whiteSpace: 'pre',
    padding: '0 10px',
    marginLeft: '-10px',
    ':hover': {
      backgroundPosition: '-100% 0',
      animation: `${animations.hover} 300ms forwards`,
      '& a': {
        textDecoration: 'none',
      },
      '& .cta': {
        animation: `${animations.evenOutHover} 300ms forwards`,
      },
    },
    '& .cta': {
      display: 'flex',
      '& .img': {
        height: '12px',
        paddingRight: '12px',
        marginTop: '6px',
      },
    },
  },
  '&.logo': {
    backgroundPosition: '-0% 0',
    backgroundSize: '200% auto',
    width: 'fit-content',
    whiteSpace: 'pre',
    marginRight: '10px',
    background: BackgroundColorEnum.BLACK,
    display: 'flex',

    '& .caption': {
      height: '40px',
      padding: '9px',
      color: fontColorEnum.GRAY,
    },
    '& .cta': {
      ':hover': {
        textDecoration: 'none',
        transition: '0.5s',
      },
      ':not(:hover)': {
        filter: 'brightness(0.4)',
        transition: '0.5s',
      },
      display: 'flex',
      '& .img': {
        margin: '5px',
        height: '32px',
      },
    },
  },

  '&.footer': {
    background: BackgroundColorEnum.BLACK,
    '& .caption': {
      color: fontColorEnum.WHITE,
    },
    ':hover': {
      '& a': {
        textDecoration: 'none',
      },
    },
    '& .cta': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      display: 'flex',
      width: 'fit-content',
      whiteSpace: 'pre',
      direction: 'rtl',
      '& .img': {
        height: '14px',
        marginTop: '5px',
        marginLeft: '34px',
        filter: 'invert(1)',
      },
    },
  },
  '&.central': {
    margin: '0 auto',
  },
  '& .left-pointing-arrow': {
    transform: 'scaleX(-1)',
    marginRight: '13px',
    height: '12px',
    marginTop: '6px',
  },
  '&.large .cta': {
    '& .caption': {
      fontSize: '32px',
    },
    '& .img': {
      paddingTop: '8px',
      height: '30px',
    },
  },
  '&.signup-event .cta': {
    '& .img': {
      // filter to convert black images into novataris blue courtesy of https://codepen.io/sosuke/pen/Pjoqqp
      filter:
        'invert(48%) sepia(82%) saturate(2814%) hue-rotate(199deg) brightness(100%) contrast(96%)',
      marginTop: '8px',
      marginRight: '16px',
      height: '22x',
    },
  },
});

type CallToActionProps = {
  type?: TypeCallToActionTypes;
  link?: InternalPropTypes.Link;
  className?: string;
};

class CallToAction extends Component<CallToActionProps> {
  static types = CallToActionTypeEnum;

  static defaultProps = {
    type: this.types.REGULAR,
    link: null,
    className: null,
  };

  render() {
    const { link, type, className } = this.props;

    if (link === null) {
      return null;
    }
    if (type === CallToAction.types.LOGO && !link.image) return null;

    const url = changeHashToQuery(link.linkTo);

    const getCaptionType = () => {
      switch (type) {
        case CallToAction.types.FOOTER:
          return Caption.types.NAVIGATIONBIG;
        case CallToAction.types.SIGNUP_EVENT:
          return Caption.types.SIGNUP_EVENT;
        default:
          return Caption.types.NAVIGATION;
      }
    };

    const renderImg = () => {
      if (type === CallToAction.types.LOGO) {
        return <Image className="img" image={link.image} />;
      }
      return (
        <img
          className={`${
            type === CallToAction.types.LEFT_POINTING
              ? 'left-pointing-arrow'
              : 'img'
          }`}
          src={angledArrow}
          alt="Arrow"
        />
      );
    };

    return (
      <div
        css={useStyles}
        className={type + (className !== null ? ` ${className}` : '')}
      >
        <NwLink
          className="cta"
          to={url}
          openInNewWindowOrTab={link.openInNewWindowOrTab}
        >
          {renderImg()}
          {!(type === CallToAction.types.LOGO) && (
            <Caption type={getCaptionType()}>{link.text}</Caption>
          )}
        </NwLink>
      </div>
    );
  }
}

export default CallToAction;
