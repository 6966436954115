import { Component } from 'react';

import LinkButton from '../atoms/LinkButton';
import BodyText from '../atoms/BodyText';
import { fontColorEnum } from '../../../constants/cms-constants';

const style = {
  '.text-link-button-text': {
    color: fontColorEnum.WHITE,
  },
};

type TextLinkButtonProps = {
  openInNewWindowOrTab?: boolean;
  url?: string;
  label?: string;
};

class TextLinkButton extends Component<TextLinkButtonProps> {
  static defaultProps = {
    openInNewWindowOrTab: false,
    url: '',
    label: '',
  };

  render() {
    const { openInNewWindowOrTab, url, label } = this.props;

    return (
      <LinkButton
        css={style}
        url={url}
        openInNewWindowOrTab={openInNewWindowOrTab}
      >
        <BodyText
          as="span"
          className="text-link-button-text"
          type={BodyText.types.BOLD16}
        >
          {label}
        </BodyText>
      </LinkButton>
    );
  }
}

export default TextLinkButton;
