import EventPastGrid from '../../components/EventPastGrid';
import { BlockInstanceProps } from '../Block';
import {
  ContentTypeEnum,
  BackgroundColorEnum,
} from '../../constants/cms-constants';
import { getPastEvents } from '../../utils/content/Events';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  { key: 'showNumbering', definition: controlOptionDefinitions.boolean },
  { key: 'seoWeight', definition: controlOptionDefinitions.SEOWeightTypes },
])
class EventPastGridBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor =
      BackgroundColorEnum[controlOptions?.backgroundColor];
    const showNumbering = controlOptions?.showNumbering;
    const seoWeight = controlOptions?.seoWeight;

    let events;
    blockContent.doQuery((content) => {
      events = content.getAllOfType(ContentTypeEnum.EVENT);
    });
    getPastEvents(events);

    return (
      <EventPastGrid
        mainHeader={block?.header?.main}
        events={events}
        backgroundColor={backgroundColor}
        showNumbering={showNumbering}
        seoWeight={seoWeight}
      />
    );
  }
}

export default EventPastGridBlock;
