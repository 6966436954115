/* eslint-disable max-classes-per-file */
import React, { Component, PropsWithChildren } from 'react';
import Alert from 'react-bootstrap/Alert';
import { compose } from 'recompose';
import Dropdown from 'react-bootstrap/Dropdown';
import { css } from '@emotion/react';

import NwLink from '../components/common/NwLink';
import share from '../assets/share.png';

import { resolveBlock } from '../utils/componentResolver';
import withAppConfig, { WithAppConfigProps } from '../utils/hocs/withAppConfig';
import { withBlockErrors } from '../utils/hocs/withErrors';
import generateEditorLinkToEntry from '../utils/contentful';
import { BlockContentSet } from '../utils/content/BlockQueries';
import {
  AppConfigType,
  AppConfigConnectorsType,
} from '../client-server-utils/appConfig';

import { colorEnum } from '../constants/colors';
import * as Breakpoints from '../constants/breakpoints';
import RichTextEmbeddedEntries from '../constants/richtextembeddedentries';
import * as ContentState from '../constants/contentstate';
import * as InternalPropTypes from '../constants/internal-types';
import { BackgroundColorEnum, fontColorEnum } from '../constants/cms-constants';

const styles = css({
  position: 'relative',
  '& .notifier-errors, .notifier-warnings, .notifier-unpublished': {
    [Breakpoints.Mobile.mq]: {
      display: 'none',
    },
    position: 'absolute',
    left: 0,
    top: '40%',
    zIndex: 1002,
    marginTop: '25px',
    '& .btn-primary, & .btn-primary.dropdown-toggle, & .btn-primary.dropdown-toggle:not(:disabled):not(.disabled):active':
      {
        color: fontColorEnum.BLACK,
        borderColor: BackgroundColorEnum.GRAY,
      },
    '&:hover': {
      opacity: 1,
    },
    '& .dropright': {
      opacity: 0.5,
      transition: 'opacity .2s ease-in-out',
      '&.show': {
        opacity: 1,
      },
      '&:hover': {
        opacity: 1,
      },
    },
    '& .dropdown-menu.show': {
      width: '350px',
      '& p': {
        whiteSpace: 'pre-wrap',
        marginLeft: '8px',
        padding: '8px 24px',
      },
    },
  },
  '& .notifier-errors': {
    marginTop: '-25px',
    '& .dropdown-toggle': {
      backgroundColor: colorEnum.red,
    },
  },
  '& .notifier-warnings .dropdown-toggle': {
    backgroundColor: colorEnum.yellow,
  },
  '& .notifier-unpublished .dropdown-toggle': {
    backgroundColor: colorEnum.lightBlue,
  },
});

type BlockWrapperProps = WithAppConfigProps &
  PropsWithChildren<{
    block: InternalPropTypes.Block; // eslint-disable-line no-use-before-define
    blockContent: BlockContentSet;
  }>;

type BlockWrapperInnerProps = BlockWrapperProps;

class BlockWrapperComponent extends Component<BlockWrapperInnerProps> {
  static defaultProps = {
    children: null,
  };

  render() {
    const { block, blockContent, children, appConfig } = this.props;
    const appSettings = appConfig.settings;
    const hasHardError = blockContent.hasHardErrors();
    const softErrors = blockContent.getSoftErrors();
    const showNotifier =
      appSettings.editorToolsEnabled &&
      (hasHardError ||
        softErrors.length > 0 ||
        (block.state &&
          (block.state.state !== ContentState.PUBLISHED ||
            block.state.aggregatedState !== ContentState.PUBLISHED)));
    const url = showNotifier
      ? generateEditorLinkToEntry(appSettings, block?.contentfulEntryId)
      : '';

    return (
      <div css={styles}>
        {showNotifier && (
          <div
            className={`notifier-${
              // eslint-disable-next-line no-nested-ternary
              hasHardError
                ? 'errors'
                : softErrors.length > 0
                ? 'warnings'
                : 'unpublished'
            }`}
            key={`${block?.contentfulEntryId}-blockwrapper`}
          >
            <Dropdown drop="right">
              <Dropdown.Toggle size="sm">
                <img
                  src={share}
                  height="20"
                  width="20"
                  alt="the-little-helper"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Header>Block</Dropdown.Header>
                <NwLink to={url}>
                  <p>{`${block?.name}`}</p>
                </NwLink>
                <Dropdown.Divider />

                <Dropdown.Header>Errors</Dropdown.Header>
                {blockContent.errors
                  .filter((error) => error.isHardError())
                  .map((error, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <p key={`blockerror-${idx}`}>{error.message}</p>
                  ))}
                <Dropdown.Divider />

                <Dropdown.Header>Warnings</Dropdown.Header>
                {softErrors.map((error, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <p key={`blockwarning-${idx}`}>{error.message}</p>
                ))}
                <Dropdown.Divider />

                <Dropdown.Header>Published state</Dropdown.Header>
                {block && block?.state ? (
                  <>
                    <p>
                      This block is {block?.state?.state}
                      {block?.state?.aggregatedState !==
                      ContentState.PUBLISHED ? (
                        <>
                          , but one or more entries this block uses are{' '}
                          <i>not</i> published
                        </>
                      ) : null}
                    </p>
                    {block?.state?.aggregatedState !==
                    ContentState.PUBLISHED ? (
                      <ul>
                        {block?.state?.remarks?.map((remark, idx) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <li key={`blockremark-${idx}`}>
                            <NwLink
                              to={generateEditorLinkToEntry(
                                appSettings,
                                remark?.entryId,
                              )}
                              openInNewWindowOrTab
                            >
                              {remark?.entryId}
                            </NwLink>{' '}
                            is {remark?.state}
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </>
                ) : (
                  <p>Published</p>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}

        {!hasHardError && children}
      </div>
    );
  }
}

export const BlockWrapper = compose<BlockWrapperInnerProps, BlockWrapperProps>(
  withAppConfig,
  withBlockErrors,
)(BlockWrapperComponent);

type BlockInstanceFuncProps = {
  block: InternalPropTypes.Block; // eslint-disable-line no-use-before-define
  Elem: React.ElementType;
  appConfig: AppConfigType;
};

export function BlockInstance({
  block,
  Elem,
  appConfig,
}: BlockInstanceFuncProps) {
  const blockContent = new BlockContentSet(block?.content ?? []);
  return (
    <BlockWrapper
      block={block}
      blockContent={blockContent}
      appConfig={appConfig}
    >
      <Elem
        block={block}
        connectors={appConfig.connectors}
        blockContent={blockContent}
      />
    </BlockWrapper>
  );
}

type EmbeddedBlockProps = {
  block: InternalPropTypes.Block; // eslint-disable-line no-use-before-define
};

type EmbeddedBlockInnerProps = EmbeddedBlockProps & WithAppConfigProps;
const initCapWord = (word) => word.charAt(0).toUpperCase() + word.substring(1);

const collapseAndCap = (line) => line.split(/\s+/).map(initCapWord).join('');
class EmbeddedBlockComponent extends Component<EmbeddedBlockInnerProps> {
  // eslint-disable-next-line class-methods-use-this
  _isAllowedBlockType(Name) {
    return RichTextEmbeddedEntries.includes(Name);
  }

  render() {
    const { block, appConfig } = this.props;
    const Elem = resolveBlock(block);
    const Name = collapseAndCap(block.type);
    let result;
    if (
      this._isAllowedBlockType(Name) ||
      this._isAllowedBlockType(`${Name}Block`)
    ) {
      result = (
        <BlockInstance block={block} Elem={Elem} appConfig={appConfig} />
      );
    } else {
      result = (
        <Alert className="alert alert-danger" role="alert">
          <strong>Error!</strong> Block type not allowed. The supported types
          are {RichTextEmbeddedEntries.join(', ')} but got {Name}.
        </Alert>
      );
    }
    return result;
  }
}

export const EmbeddedBlock = compose<
  EmbeddedBlockInnerProps,
  EmbeddedBlockProps
>(
  withBlockErrors,
  withAppConfig,
)(EmbeddedBlockComponent);

type BlockProps = {
  block: InternalPropTypes.Block; // eslint-disable-line no-use-before-define
};

type BlockInnerProps = WithAppConfigProps & BlockProps;

export type BlockInstanceProps = {
  block: InternalPropTypes.Block; // eslint-disable-line no-use-before-define
  blockContent: BlockContentSet;
  connectors: AppConfigConnectorsType;
};

class Block extends Component<BlockInnerProps> {
  render() {
    const { block, appConfig } = this.props;

    const Elem = resolveBlock(block);
    let result = <p>Block not found</p>;
    if (Elem !== null) {
      result = (
        <BlockInstance block={block} Elem={Elem} appConfig={appConfig} />
      );
    }
    return result;
  }
}

export default compose<BlockInnerProps, BlockProps>(
  withAppConfig,
  withBlockErrors,
)(Block as React.ComponentType<any>); // eslint-disable-line @typescript-eslint/no-explicit-any
