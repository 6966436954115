import { Component } from 'react';
import { css } from '@emotion/react';

import Title from '../atoms/Title';
import BodyText from '../atoms/BodyText';
import Image from '../atoms/Image';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import TitleTypes from '../../../constants/title-types';
import { SizesType } from '../../../types/ts/imgixQuery';
import ScreenSize from '../../../constants/screenSize';

import NwLink from '../NwLink';

import { getStringFromRichText } from '../../../utils/content/RichTextConverter';

import { TypeSEOWeightTypes } from '../../../constants/cms-constants';
import SEOWeights from '../../../constants/cms/control-options/seo-weights';
import getSEOWeight from '../../../utils/SEOWeight';

const styles = css({
  marginBottom: '20px',
  alignContent: 'end',
  [Breakpoints.Mobile.mq]: {
    maxHeight: '100%',
  },
  [Breakpoints.TabletUp.mq]: {
    maxHeight: '100%',
  },
  '& .clickable-area': {
    minWidth: '0',
    '&:hover': {
      textDecoration: 'none',
    },
    '& .image': {
      objectFit: 'cover',
      [Breakpoints.Mobile.mq]: {
        maxHeight: '336px',
        maxWidth: '100%',
        minWidth: '80%',
      },
      [Breakpoints.TabletUp.mq]: {
        maxHeight: '176px',
        width: '100%',
      },
    },
    '& .text-area': {
      [Breakpoints.Mobile.mq]: {
        maxWidth: '100%',
      },
      paddingTop: '16px',
      paddingBottom: '8px',
    },
  },
});

type SubpageTeaserProps = {
  image?: InternalPropTypes.Image;
  title?: InternalPropTypes.RichText;
  text?: InternalPropTypes.RichText;
  link?: InternalPropTypes.Link;
  seoWeight?: TypeSEOWeightTypes;
};

class SubpageTeaser extends Component<SubpageTeaserProps> {
  static defaultProps = {
    image: null,
    title: null,
    text: null,
    link: null,
    seoWeight: SEOWeights.H2,
  };
  render() {
    const { image, title, text, link, seoWeight } = this.props;

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '504', h: '336' },
      [ScreenSize.Tablet]: { w: '436', h: '176' },
      [ScreenSize.Desktop]: { w: '318', h: '176' },
    };

    const addSEOWeight = getSEOWeight(
      seoWeight,
      SubpageTeaser.defaultProps.seoWeight,
    );

    const titleAsString = getStringFromRichText(title);
    return (
      <div css={styles} className="subpage-teaser">
        {link && (
          <NwLink
            to={link.linkTo}
            className="clickable-area"
            aria-label={titleAsString}
            openInNewWindowOrTab={link.openInNewWindowOrTab}
          >
            <div className="image-container">
              <div className="image-container-inner">
                <Image image={image} sizes={sizes} />
              </div>
            </div>
            <div className="text-area">
              <BodyText as={addSEOWeight} type={BodyText.types.BOLD16}>
                {title}
              </BodyText>
              <BodyText type={BodyText.types.STATIC16}>{text}</BodyText>
            </div>
          </NwLink>
        )}
      </div>
    );
  }
}

export default SubpageTeaser;
