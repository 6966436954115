import { Component } from 'react';
import { css } from '@emotion/react';

import BodyText from '../atoms/BodyText';

import angledArrow from '../../../assets/angledArrow.png';
import {
  BackgroundColorEnum,
  fontColorEnum,
} from '../../../constants/cms-constants';
import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';

const styles = css({
  '& .file-request-form, .status-message': {
    [Breakpoints.TabletDown.mq]: {
      marginTop: '20px',
      maxWidth: '100%',
    },
  },
  '& .file-request-form-description': {
    marginBottom: '20px',
  },
  '& .status-message': {
    maxWidth: '100%',
    textAlign: 'center',
  },
  '& .success-status-email-message': {
    paddingBottom: '20px',
  },
  '& .name-input, .email-input': {
    border: 0,
    outline: 0,
    borderBottom: '1px solid grey',
    color: fontColorEnum.BLACK,
    backgroundColor: BackgroundColorEnum.TRANSPARENT,
    marginTop: 0,
    marginBottom: '20px',
    width: '80%',
    fontSize: '18px',
  },
  '& .submit-button': {
    backgroundColor: BackgroundColorEnum.TRANSPARENT,
    border: 0,
    outline: 0,
    position: 'absolute',
    display: 'flex',
    textAlign: 'center',
    left: '50%',
    '& .submit-arrow': {
      height: '20px',
      width: '25px',
      marginRight: '10px',
      marginTop: '13px',
    },
  },
});

type FileRequestFormProps = {
  emailMessage?: string;
  resubmitMessage?: InternalPropTypes.RichText;
  failedSubmitMessage?: InternalPropTypes.RichText;
  submitButtonText?: InternalPropTypes.RichText;
  namePlaceholder?: string;
  emailPlaceholder?: string;
  sendingMessage?: InternalPropTypes.RichText;
  links?: { text: string; url: string }[];
  mailContent?: InternalPropTypes.MailContent;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  submitFileRequest?: (...args: any[]) => Promise<Response>;
};

interface IFileRequestFormState {
  formName: string;
  email: string;
  showForm: boolean;
  successfulSubmit: boolean;
  submitActive: boolean;
}

class FileRequestForm extends Component<
  FileRequestFormProps,
  IFileRequestFormState
> {
  static defaultProps = {
    emailMessage: null,
    resubmitMessage: null,
    failedSubmitMessage: null,
    namePlaceholder: null,
    emailPlaceholder: null,
    submitButtonText: null,
    sendingMessage: null,
    links: null,
    mailContent: null,
    submitFileRequest: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      formName: '',
      email: '',
      showForm: true,
      successfulSubmit: false,
      submitActive: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  handleEnter(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.state.submitActive) {
      this.setState({ showForm: false, submitActive: true });
      this.props
        .submitFileRequest(
          this.state.formName,
          this.state.email,
          this.props.links,
          this.props.mailContent,
        )
        .then((response) => {
          this.setState({ successfulSubmit: response.ok });
        })
        .catch(() => {
          this.setState({ successfulSubmit: false });
        })
        .finally(() => {
          this.setState({ submitActive: false });
        });
    }
  }

  handleNameChange(e) {
    this.setState({ formName: e.target.value });
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleClick() {
    this.setState({ showForm: true });
  }

  render() {
    const {
      emailMessage,
      resubmitMessage,
      failedSubmitMessage,
      submitButtonText,
      namePlaceholder,
      emailPlaceholder,
      sendingMessage,
    } = this.props;

    return (
      <div css={styles}>
        {this.state.showForm && !this.state.submitActive && (
          <div className="file-request-form">
            <form autoComplete="off" onSubmit={(e) => this.handleSubmit(e)}>
              <input
                className="name-input"
                autoComplete="invalid-string"
                type="text"
                required
                placeholder={namePlaceholder}
                onKeyDown={(e) => this.handleEnter(e)}
                onChange={(e) => this.handleNameChange(e)}
              />

              <input
                className="email-input"
                autoComplete="invalid-string"
                type="text"
                pattern="[^@\s]+@[^@\s]+\.+[^@\s]+"
                required
                placeholder={emailPlaceholder}
                onKeyDown={(e) => this.handleEnter(e)}
                onChange={(e) => this.handleEmailChange(e)}
              />
              <button type="submit" className="submit-button">
                <img className="submit-arrow" src={angledArrow} alt="arrow" />
                <BodyText
                  className="file-request-submit-text"
                  type={BodyText.types.ARTICLE}
                >
                  {submitButtonText}
                </BodyText>
              </button>
            </form>
          </div>
        )}

        {this.state.submitActive && (
          <BodyText className="status-message" type={BodyText.types.ARTICLE}>
            {sendingMessage}
          </BodyText>
        )}

        {!this.state.showForm &&
          this.state.successfulSubmit &&
          !this.state.submitActive && (
            <div className="success-status-message">
              <BodyText className="success-status-email-message">
                {emailMessage + this.state.email}
              </BodyText>
              <div
                onClick={() => this.handleClick()}
                onKeyDown={() => this.handleEnter}
                role="button"
                tabIndex={0}
              >
                <BodyText className="success-status-message">
                  {resubmitMessage}
                </BodyText>
              </div>
            </div>
          )}

        {!this.state.showForm &&
          !this.state.successfulSubmit &&
          !this.state.submitActive && (
            <div
              onClick={() => this.handleClick()}
              onKeyDown={() => this.handleEnter}
              role="button"
              tabIndex={0}
            >
              <BodyText
                className="status-message"
                type={BodyText.types.ARTICLE}
              >
                {failedSubmitMessage}
              </BodyText>
            </div>
          )}
      </div>
    );
  }
}

export default FileRequestForm;
