import * as InternalPropTypes from '../../constants/internal-types';
import {
  ContentTypeEnum,
  BackgroundColorEnum,
} from '../../constants/cms-constants';
import BuzzList from '../../components/BuzzList';
import { BlockInstanceProps } from '../Block';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class BuzzListBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor =
      BackgroundColorEnum[controlOptions?.backgroundColor];

    let snippets: Array<InternalPropTypes.NamedSnippet> = [];
    blockContent.doQuery((content) => {
      snippets = content.getAllOfType(
        ContentTypeEnum.SNIPPET,
      ) as Array<InternalPropTypes.NamedSnippet>;
    });

    const buzzWords = snippets.map((s) => s?.text);

    return (
      <BuzzList
        header={block?.header?.main}
        text={block?.bodyText}
        buzzWords={buzzWords}
        link={block?.link}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default BuzzListBlock;
