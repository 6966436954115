import { BlockInstanceProps } from '../Block';
import ImageGrid2Cells from '../../components/ImageGrid2Cells';
import {
  ContentTypeEnum,
  BackgroundColorEnum,
} from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class ImageGrid2CellsBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor =
      BackgroundColorEnum[controlOptions?.backgroundColor];

    let images;
    blockContent.doQuery((content) => {
      images = content.getFixedAmountOfType(ContentTypeEnum.IMAGE, 2);
    });

    return (
      <ImageGrid2Cells images={images} backgroundColor={backgroundColor} />
    );
  }
}

export default ImageGrid2CellsBlock;
