import { Component } from 'react';
import { CSSObject, css } from '@emotion/react';

import RichTextWrapper from '../RichTextWrapper';
import RichTextRenderer from '../../../utils/content/RichTextRenderer';

import font from '../../../constants/fonts';
import * as InternalPropTypes from '../../../constants/internal-types';
import { defaultColors } from '../../../constants/colors';
import { fontColorEnum } from '../../../constants/cms-constants';

const useStyles = css({
  padding: '0',
  margin: '0',
  color: defaultColors.header,
  '&.normal': {
    ...font.GNLight,
    fontSize: '12px',
  },
  '&.heavy': {
    ...font.GNMedium,
    fontSize: '12px',
  },
  '&.section': {
    ...font.GNBook,
    fontSize: '14px',
  },
  '&.hero': {
    ...font.GNBook,
    fontSize: '12px',
    lineHeight: '18px',
  },
  '&.hero2': {
    ...font.GNBook,
    fontSize: '14px',
    lineHeight: '18px',
  },
  '&.navigation': {
    ...font.GNBold,
    fontSize: '16px',
  },
  '&.navigation-footer': {
    ...font.GNMedium,
    fontSize: '21px',
    lineHeight: '25px',
  },
  '&.navigation-big': {
    ...font.GNMedium,
    fontSize: '21px',
  },
  '&.menu': {
    ...font.GNMedium,
    fontSize: '13px',
    lineHeight: '16px',
  },
  '&.social': {
    ...font.GNMedium,
    fontSize: '13px',
  },
  '&.language': {
    ...font.GNBook,
    fontSize: '12px',
    lineHeight: '16px',
  },
  '&.title-base,&.title-case': {
    fontSize: '18px',
    lineHeight: '32px',
    letterSpacing: '-0.15px',
  },
  '&.title-base': {
    ...font.GNBook,
  },
  '&.title-case': {
    ...font.GNLight,
  },
  '&.title-profile': {
    ...font.GNBook,
    fontSize: '18px',
    lineHeight: '32px',
    letterSpacing: '-0.15px',
  },
  '&.title-contact': {
    ...font.GNLight,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '-0.25px',
  },
  '&.title-author': {
    ...font.GNMedium,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '-0.42px',
  },
  '&.signup-event': {
    ...font.GNBold,
    fontSize: '20px',
    color: fontColorEnum.BLUE,
  },
});

enum CaptionType {
  SECTION = 'section',
  HERO = 'hero',
  NAVIGATION = 'navigation',
  NAVIGATIONBIG = 'navigation-big',
  SOCIAL = 'social',
  TITLEPROFILE = 'title-profile',
  TITLEAUTHOR = 'title-author',
  SIGNUP_EVENT = 'signup-event',
}

type CaptionProps = {
  type?: CaptionType;
  css?: CSSObject;
  as?: React.ElementType;
  className?: string;
  children?: InternalPropTypes.StringOrRichTextOrElementOrNestedArrays;
};

class Caption extends Component<CaptionProps> {
  static types = CaptionType;

  static defaultProps = {
    type: CaptionType.HERO,
    css: null,
    as: 'p',
    children: null,
    className: null,
  };

  render() {
    const { children, type, css, as, className } = this.props;

    const Elem = as;
    let output = children;
    if (InternalPropTypes.isRichText(children)) {
      output = (
        <RichTextWrapper
          richText={children as InternalPropTypes.RichText}
          renderFunction={RichTextRenderer.regularTextOnly}
        />
      );
    }

    return (
      <Elem
        css={[useStyles, css]}
        className={`caption ${type} ${className || ''}`}
      >
        {output}
      </Elem>
    );
  }
}

export default Caption;
