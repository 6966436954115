import ImageHelper from './imageHelper';
import { ImageFile } from '../../../constants/internal-types';

class SimpleImageHelper implements ImageHelper {
  private _image: ImageFile;

  constructor(image: ImageFile) {
    this._image = image;
  }

  // eslint-disable-next-line class-methods-use-this
  isAnimation() {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  supportsImgix() {
    return false;
  }

  getImageUrl() {
    return this._image.url;
  }

  getAssetName() {
    return this._image.assetName;
  }

  getImageSize() {
    return {
      widthInPx: this._image.widthInPx,
      heightInPx: this._image.heightInPx,
      aspectRatio: this._image.widthInPx / this._image.heightInPx,
    };
  }

  getImageSizeInBytes() {
    return this._image.sizeInBytes;
  }

  getRawImage(): ImageFile {
    return this._image;
  }
}

export default SimpleImageHelper;
