import React, { Component } from 'react';
import { css } from '@emotion/react';

import LetsTalkMolecule from './common/molecules/LetsTalk';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import GridEvenSplit from './common/atoms/GridEvenSplit';
import Title from './common/atoms/Title';
import straightArrow from '../assets/straightArrow.png';

import MessageForm from './common/molecules/MessageForm';
import BookingForm from './common/molecules/BookingForm';
import Location from './common/molecules/Location';
import Grid2CellUnevenSplit from './common/atoms/Grid2CellUnevenSplit';
import ProfileContact from './common/molecules/ProfileContact';

import { getStringFromRichText } from '../utils/content/RichTextConverter';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import TitleTypes from '../constants/title-types';
import {
  BackgroundColorEnum,
  fontColorEnum,
  TypeBackgroundColorTypes,
} from '../constants/cms-constants';

const styles = css({
  backgroundColor: BackgroundColorEnum.TRANSPARENT,
  [Breakpoints.Mobile.mq]: {
    paddingTop: '61px',
    paddingBottom: '61px',
  },
  [Breakpoints.TabletUp.mq]: {
    paddingTop: '125px',
    paddingBottom: '125px',
  },
  '& .block-container-active': {
    width: '100%',
    maxWidth: '100%',
    [Breakpoints.TabletDown.mq]: {
      marginTop: '32px',
    },
  },
  '& .block-container-not-active': {
    display: 'none',
    '& .location-container-active': {
      display: 'none',
    },
  },
  '& .button-pointer': {
    textAlign: 'left',
    backgroundColor: BackgroundColorEnum.TRANSPARENT,
    border: 'none',
    padding: 0,
    display: 'flex',
    outline: 0,
    paddingBottom: '8px',
    paddingTop: '8px',
    width: 'max-content',
    '& .navigable': {
      fontSize: '24px',
      display: 'flex',
      marginBottom: 0,
      '&.-not-active': {
        color: fontColorEnum.GRAY,
      },
      '&.-active': {
        color: fontColorEnum.WHITE,
      },
    },
    '& .pointer-arrow-not-active': {
      height: '35px',
      width: '35px',
      marginLeft: '10px',
      marginTop: '-7px',
      backgroundColor: BackgroundColorEnum.TRANSPARENT,
    },
    '& .pointer-arrow-active': {
      height: '35px',
      width: '35px',
      marginLeft: '10px',
      marginTop: '-7px',
      backgroundColor: BackgroundColorEnum.TRANSPARENT,
    },
  },
  '& .outer-left-cell': {
    '& .body-text.hero': {
      marginBottom: '70px',
      maxWidth: '60%',
      '& a': {
        marginRight: '20px',
        color: fontColorEnum.BRIGHTGRAY,
      },
    },
  },
  '& .profile-contact': {
    [Breakpoints.TabletUp.mq]: {
      marginBottom: '100px',
    },
    width: '100%',
  },
  '& .booking-form': {
    [Breakpoints.TabletUp.mq]: {
      paddingBottom: '140px',
    },
  },
  '& .message-form': {
    [Breakpoints.TabletUp.mq]: {
      paddingBottom: '130px',
    },
  },
});

type LetsTalkComponentProps = {
  headerText?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  images?: InternalPropTypes.Image[];
  profiles?: InternalPropTypes.Person[];
  mediaItems?: InternalPropTypes.MediaItem[];
  backgroundColor?: TypeBackgroundColorTypes;
  pointerHeaders?: InternalPropTypes.NamedSnippet[];
  messageFormDescription?: InternalPropTypes.NamedSnippet;
  bookingFormDescription?: InternalPropTypes.NamedSnippet;
  submitMessage?: InternalPropTypes.NamedSnippet;
  failedSubmitMessage?: InternalPropTypes.NamedSnippet;
  sendingMessage?: InternalPropTypes.NamedSnippet;
  submitButtonText?: InternalPropTypes.NamedSnippet;
  namePlaceholder?: InternalPropTypes.NamedSnippet;
  emailPlaceholder?: InternalPropTypes.NamedSnippet;
  messagePlaceholder?: InternalPropTypes.NamedSnippet;
  datePlaceholder?: InternalPropTypes.NamedSnippet;
  participantsPlaceholder?: InternalPropTypes.NamedSnippet;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  submitContactForm?: (...args: any[]) => Promise<Response>;
  submitBookingForm?: (...args: any[]) => Promise<Response>;
};

interface ILetsTalkComponentState {
  active: number;
  stopAnimation: boolean;
}

class LetsTalkComponent extends Component<
  LetsTalkComponentProps,
  ILetsTalkComponentState
> {
  static defaultProps = {
    headerText: null,
    bodyText: null,
    images: [],
    pointerHeaders: null,
    messageFormDescription: null,
    submitMessage: null,
    failedSubmitMessage: null,
    sendingMessage: null,
    submitButtonText: null,
    namePlaceholder: null,
    emailPlaceholder: null,
    messagePlaceholder: null,
    datePlaceholder: null,
    participantsPlaceholder: null,
    bookingFormDescription: null,
    profiles: [],
    mediaItems: [],
    backgroundColor: null,
    submitBookingForm: () => null,
    submitContactForm: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      stopAnimation: false,
    };
    this.changeIndex = this.changeIndex.bind(this);
  }

  changeIndex(index) {
    this.setState({
      active: index,
      stopAnimation: true,
    });
  }

  render() {
    const {
      headerText,
      bodyText,
      images,
      profiles,
      mediaItems,
      backgroundColor,
      pointerHeaders,
      bookingFormDescription,
      messageFormDescription,
      submitMessage,
      failedSubmitMessage,
      submitButtonText,
      namePlaceholder,
      emailPlaceholder,
      messagePlaceholder,
      datePlaceholder,
      participantsPlaceholder,
      submitContactForm,
      submitBookingForm,
      sendingMessage,
    } = this.props;
    const { active, stopAnimation } = this.state;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    const submitImage = images[2];

    return (
      <div css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <div className="lets-talk-outer-grid">
              <Grid2CellUnevenSplit
                leftCellCount={7}
                rightCellCount={5}
                snapAtBreakpoint={Breakpoints.TabletDown}
                onRenderCell1={() => (
                  <div className="outer-left-cell">
                    <div className="text-animation-container">
                      <div
                        className={`text-animation${
                          stopAnimation ? '-stopped' : ''
                        }`}
                      >
                        <LetsTalkMolecule
                          header={headerText}
                          bodyText={bodyText}
                        />
                      </div>
                    </div>
                    {pointerHeaders.map((item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className="text-animation-container" key={index}>
                        <div className="text-animation">
                          <button
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            type="button"
                            className="button-pointer"
                            onClick={() => this.changeIndex(index)}
                          >
                            <Title
                              type={TitleTypes.NAVIGABLE}
                              className={`-${
                                active === index ? 'active' : 'not-active'
                              }`}
                            >
                              {item.text}
                            </Title>
                            <img
                              className={`pointer-arrow-${
                                active === index ? 'active' : 'not-active'
                              }`}
                              src={straightArrow}
                              alt="arrow"
                              style={{
                                filter: `invert(${
                                  active === index ? '100%' : '65%'
                                })`,
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                onRenderCell2={() => (
                  <div>
                    {active === 0 && (
                      <GridEvenSplit
                        onRenderCells={[
                          () => (
                            <ProfileContact
                              stopAnimation={stopAnimation}
                              clickableContactInfo
                              {...profiles[0]}
                            />
                          ),
                          () => (
                            <ProfileContact
                              stopAnimation={stopAnimation}
                              {...profiles[1]}
                            />
                          ),
                        ]}
                      />
                    )}

                    {active === 1 && (
                      <MessageForm
                        description={
                          messageFormDescription
                            ? messageFormDescription.text
                            : null
                        }
                        submitMessage={
                          submitMessage ? submitMessage.text : null
                        }
                        sendingMessage={
                          sendingMessage ? sendingMessage.text : null
                        }
                        failedSubmitMessage={
                          failedSubmitMessage ? failedSubmitMessage.text : null
                        }
                        submitButtonText={
                          submitButtonText ? submitButtonText.text : null
                        }
                        namePlaceholder={
                          namePlaceholder
                            ? getStringFromRichText(namePlaceholder.text)
                            : ''
                        }
                        emailPlaceholder={
                          emailPlaceholder
                            ? getStringFromRichText(emailPlaceholder.text)
                            : ''
                        }
                        messagePlaceholder={
                          messagePlaceholder
                            ? getStringFromRichText(messagePlaceholder.text)
                            : ''
                        }
                        submit={submitContactForm}
                      />
                    )}

                    {active === 2 && (
                      <BookingForm
                        description={
                          bookingFormDescription
                            ? bookingFormDescription.text
                            : null
                        }
                        submitMessage={
                          submitMessage ? submitMessage.text : null
                        }
                        sendingMessage={
                          sendingMessage ? sendingMessage.text : null
                        }
                        failedSubmitMessage={
                          failedSubmitMessage ? failedSubmitMessage.text : null
                        }
                        submitButtonText={
                          submitButtonText ? submitButtonText.text : null
                        }
                        namePlaceholder={
                          namePlaceholder
                            ? getStringFromRichText(namePlaceholder.text)
                            : ''
                        }
                        emailPlaceholder={
                          emailPlaceholder
                            ? getStringFromRichText(emailPlaceholder.text)
                            : ''
                        }
                        datePlaceholder={
                          datePlaceholder
                            ? getStringFromRichText(datePlaceholder.text)
                            : ''
                        }
                        participantsPlaceholder={
                          participantsPlaceholder
                            ? getStringFromRichText(
                                participantsPlaceholder.text,
                              )
                            : ''
                        }
                        submit={submitBookingForm}
                      />
                    )}

                    {active === 3 && (
                      <Location
                        locImage={mediaItems[0].image}
                        locCaption={mediaItems[0].header}
                        locButtonText={mediaItems[0].lightHeader}
                        mapCaption={mediaItems[1].header}
                        mapButtonText={mediaItems[1].lightHeader}
                      />
                    )}
                  </div>
                )}
              />
            </div>
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default LetsTalkComponent;
