import { SEOWeightsEnum } from '../constants/cms-constants';

export default function getSEOWeight(seoWeight, defaultValue) {
  let seoTag;
  switch (seoWeight.toLowerCase()) {
    case SEOWeightsEnum.P:
      seoTag = 'p';
      break;
    case SEOWeightsEnum.H1:
      seoTag = 'h1';
      break;
    case SEOWeightsEnum.H2:
      seoTag = 'h2';
      break;
    case SEOWeightsEnum.H3:
      seoTag = 'h3';
      break;
    case SEOWeightsEnum.H4:
      seoTag = 'h4';
      break;
    default:
      seoTag = defaultValue;
  }
  return seoTag;
}

export function getLowerSEOWeight(seoWeight) {
  let seoTag;
  switch (seoWeight) {
    case SEOWeightsEnum.P:
      seoTag = 'p';
      break;
    case SEOWeightsEnum.H1:
      seoTag = 'h2';
      break;
    case SEOWeightsEnum.H2:
      seoTag = 'h3';
      break;
    case SEOWeightsEnum.H3:
      seoTag = 'h4';
      break;
    case SEOWeightsEnum.H4:
      seoTag = 'p';
      break;
    default:
      seoTag = 'p';
  }
  return seoTag;
}
