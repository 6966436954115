import * as contentful from 'contentful';
import { CreateClientParams } from 'contentful';
import { ContentTypeEnum } from '../../constants/cms-constants';
import AppSettings from '../AppSettings';
import BaseConnector from './BaseConnector';
import DependencyInput from '../../utils/reporterInput/DependencyInput';
import ErrorInput from '../../utils/reporterInput/ErrorInput';
import { SeverityLevels } from '../../constants/reporterServiceInputs';

function getCorrectResponseUrl(target, baseURL, urlCalled) {
  const url1 = baseURL.slice(0, baseURL.indexOf('/', baseURL.indexOf(target)));
  const url2 = urlCalled.slice(urlCalled.indexOf(target) + target.length);
  return url1 + url2;
}

const getContentfulEntries = (
  contentfulSettings: contentful.CreateClientParams,
  query: any,
  afterFetch: (dependencyInput: DependencyInput | ErrorInput) => void,
): Promise<contentful.EntryCollection<unknown>> => {
  let bytesReceived: number;
  let urlCalled: string;
  let statusCode: number;
  let name: string;
  let startTime: Date;
  let duration: number;
  let endTime: Date;
  let success: boolean;
  let type: string;
  let method: string;
  let properties: object;
  let data: string;
  const target = contentfulSettings.host;

  const requestSettings = {
    ...contentfulSettings,
    ...{ removeUnresolved: true },
    requestLogger: (config) => {
      // eslint-disable-next-line no-param-reassign
      config.meta = { startTime: new Date() };
    },
    // this is the only way to get the unparsed data from the contentful sdk
    responseLogger: (res) => {
      endTime = new Date();
      startTime = res.config.meta.startTime;
      duration = endTime.getTime() - startTime.getTime();
      urlCalled = res.request.path || res.request.responseURL;
      statusCode = res.status;
      method = res.config.method.toUpperCase();
      success = !!res;

      if (res.request.constructor.name === 'XMLHttpRequest') {
        type = 'Ajax';
        name = [
          method,
          getCorrectResponseUrl(target, res.config.baseURL, urlCalled),
        ].join(' ');
        data = name;
        properties = { HttpMethod: method };
      } else {
        type = 'HTTP';
        name = [method, urlCalled.split('?')[0]].join(' ');
        data = res.request.res.responseUrl;
      }

      bytesReceived = res.headers
        ? parseInt(res.headers['content-length'], 10)
        : 0;
    },
  };
  const promise = contentful.createClient(requestSettings).getEntries(query);

  promise
    .then(() => {
      const dependency = new DependencyInput(
        type,
        name,
        data,
        startTime,
        duration,
        success,
        statusCode,
        target,
        properties,
      );
      afterFetch(dependency);
      console.debug(
        `Contentful request [${statusCode}] ${bytesReceived} bytes ${urlCalled}`,
      );
    })
    .catch((e) => {
      const error = ErrorInput.createFromException(
        e,
        `Failed to load contentful data: ${query?.content_type} `,
      );
      error.severityLevel = SeverityLevels.CRITICAL;
      afterFetch(error);
    });
  return promise;
};

const getPages =
  (
    settings: CreateClientParams,
    locale: string,
    afterFetch: (dependencyInput: DependencyInput | ErrorInput) => void,
  ) =>
  () =>
    getContentfulEntries(
      settings,
      {
        content_type: ContentTypeEnum.PAGE,
        locale,
        limit: 1000,
        select: 'sys,fields',
      },
      afterFetch,
    );

const getPagesAsReferences =
  (
    settings: CreateClientParams,
    locale: string,
    afterFetch: (dependencyInput: DependencyInput | ErrorInput) => void,
  ) =>
  () =>
    getContentfulEntries(
      settings,
      {
        content_type: ContentTypeEnum.PAGE,
        locale,
        include: 1,
        limit: 1000,
        select: 'sys,fields',
      },
      afterFetch,
    );

const getRoot =
  (
    settings: CreateClientParams,
    locale: string,
    afterFetch: (dependencyInput: DependencyInput | ErrorInput) => void,
  ) =>
  () =>
    getContentfulEntries(
      settings,
      {
        content_type: ContentTypeEnum.ROOT,
        include: 2,
        locale,
        select: 'sys,fields',
      },
      afterFetch,
    );

const getPageAndBlocks =
  (
    settings: CreateClientParams,
    locale: string,
    afterFetch: (dependencyInput: DependencyInput | ErrorInput) => void,
  ) =>
  (contentfulEntryId) =>
    getContentfulEntries(
      settings,
      {
        'sys.id': contentfulEntryId,
        include: 4,
        locale,
      },
      afterFetch,
    );

const getPageInAllLanguages =
  (
    settings: CreateClientParams,
    _: string,
    afterFetch: (dependencyInput: DependencyInput | ErrorInput) => void,
  ) =>
  (contentfulEntryId) =>
    getContentfulEntries(
      settings,
      {
        'sys.id': contentfulEntryId,
        locale: '*',
      },
      afterFetch,
    );

const getBlock =
  (
    settings: CreateClientParams,
    locale: string,
    afterFetch: (dependencyInput: DependencyInput | ErrorInput) => void,
  ) =>
  (contentfulEntryId) =>
    getContentfulEntries(
      settings,
      {
        content_type: ContentTypeEnum.BLOCK,
        'sys.id': contentfulEntryId,
        include: 2,
        select: 'sys,fields',
        locale,
      },
      afterFetch,
    );

const getEvents =
  (
    settings: CreateClientParams,
    locale: string,
    afterFetch: (dependencyInput: DependencyInput | ErrorInput) => void,
  ) =>
  () =>
    getContentfulEntries(
      settings,
      {
        content_type: ContentTypeEnum.EVENT,
        include: 4,
        locale,
        select: 'sys,fields',
      },
      afterFetch,
    );

const getConfiguration =
  (
    settings: CreateClientParams,
    locale: string,
    afterFetch: (dependencyInput: DependencyInput | ErrorInput) => void,
  ) =>
  (contentfulEntryId) =>
    getContentfulEntries(
      settings,
      {
        content_type: ContentTypeEnum.TECHNICALCONFIGURATION,
        'sys.id': contentfulEntryId,
        select: 'sys,fields',
        locale,
      },
      afterFetch,
    );

export type ContentfulResult = {
  content: contentful.EntryCollection<unknown>;
  preview: contentful.EntryCollection<unknown> | undefined;
  language: string;
  isPreview: boolean;
};

type QueryFunctionType = (
  settings: CreateClientParams,
  locale: string,
  afterFetch: (dependencyInput: DependencyInput | ErrorInput) => void,
) => (...args: any[]) => Promise<contentful.EntryCollection<unknown>>;

export const EntriesQueries: Record<string, QueryFunctionType> = {
  getPages,
  getRoot,
  getPageAndBlocks,
  getPagesAsReferences,
  getPageInAllLanguages,
  getBlock,
  getEvents,
  getConfiguration,
};

export default class ContentfulConnector extends BaseConnector {
  // eslint-disable-next-line no-useless-constructor
  constructor(private appSettings: AppSettings) {
    super();
  }

  call(
    queryFunc: QueryFunctionType,
    ...queryArgs: Array<any>
  ): Promise<ContentfulResult> {
    const {
      contentful: { current, isPreview },
      language,
    } = this.appSettings;

    let primedQueryFunc = queryFunc(
      current.content,
      language.currentIso,
      this.afterFetch,
    );
    // eslint-disable-next-line prefer-spread
    const promises = [primedQueryFunc.apply(null, queryArgs)];
    if (isPreview) {
      primedQueryFunc = queryFunc(
        current.preview,
        language.currentIso,
        this.afterFetch,
      );
      // eslint-disable-next-line prefer-spread
      promises.push(primedQueryFunc.apply(null, queryArgs));
    }
    return new Promise((resolve, reject) => {
      Promise.all(promises)
        .then((values) => {
          resolve({
            content: values[0],
            preview: values[1],
            language: language.currentIso,
            isPreview,
          });
        })
        .catch(reject);
    });
  }
}
